<template>
  <div class="absolute-fill d-flex justify-center align-center">
    <div ref="btn" v-bind:style="{ transform: `scale(${blob.btn.scale || 1})` }">
      <!-- Note that we use mousedown.capture to make sure the 'edit' event triggers before the blob becomes selected (since we ignore it on the first attempt) -->
      <v-btn
        v-blur
        style="pointer-events: auto;"
        Xv-bind:style="{ 'pointer-events': isInteractive ? 'auto' }"
        x-large rounded height="56" min-width="56" elevation="6" dark target="_blank"
        :color="blob.btn.color || 'primary'"
        v-bind="(isInteractive && blob.btn.url) ? { href: blob.btn.url } : {}"
        @click="!(isOwner && [ 'purchase', 'edit' ].includes(mode)) && $emit('click')"
      >
        <span v-if="blob.btn.text" v-bind:class="{ 'mr-1': blob.btn.url }" v-html="blob.btn.text" />
        <v-icon v-if="!blob.btn.form" v-html="mdiOpenInNew" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js';

export default {
  name: 'btn-blob',

  props: [ 'isOwner', 'mode', 'blob' ],

  created() {
    Object.assign(this, { mdiOpenInNew });
  },

  computed: {
    isInteractive() {
      return !(this.isOwner && [ 'purchase', 'edit' ].includes(this.mode));
    }
  },

  methods: {
    async adjust() {
      // We do this asynchronously to avoid interfering with updates made elsewhere
      await this.$nextTick();
      const w = this.$refs.btn.clientWidth * (this.blob.btn.scale || 1);
      if (w != this.blob.w)
        this.blob.w = w;
      const h = this.$refs.btn.clientHeight * (this.blob.btn.scale || 1);
      if (h != this.blob.h)
        this.blob.h = h;
    }
  },

  watch: {
    blob: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        this.adjust();
        this.$emit('loaded');
      }
    },

    'blob.btn.scale'() {
      this.adjust();
    },

    'blob.btn.text'() {
      this.adjust();
    }
  }
}
</script>
