<template>
  <div>
    <v-btn class="btn-like-google-sign-in" elevation="0" outlined @click="$emit('click')">
      <v-icon v-html="mdiIncognitoCircle" />
      <span>Stay anonymous</span>
    </v-btn>
  </div>
</template>


<script>
import { mdiIncognitoCircle } from '@mdi/js';

export default {
  name: 'sign-in-btn-anonymous',
  created() {
    Object.assign(this, { mdiIncognitoCircle });
  }
};
</script>
