<template>
  <span>
    <span v-if="prefix" class="prefix" v-html="code" />
    <span v-if="negative">−</span>
    <span v-html="symbol" />
  </span>
</template>

<style scoped>
.prefix {
  font-size: .5em;
  margin-right: .5em;
}
</style>

<script>
import { AMBIGUOUS_CURRENCY_SYMBOLS, COUNTRY_CODE, CURRENCY_CODES, CURRENCY_SYMBOLS } from '@/utils/intl.js';

const LOCALE_CURRENCY = CURRENCY_CODES[COUNTRY_CODE] || 'USD';
const LOCALE_CURRENCY_SYMBOL = CURRENCY_SYMBOLS[LOCALE_CURRENCY];

export default {
  name: 'currency',

  props: [ 'code', 'negative' ],

  computed: {
    symbol() {
      return CURRENCY_SYMBOLS[this.code] || '';
    },

    prefix() {
      // We don't want to pollute the UI if we're only looking at currencies in our locale
      return (this.symbol == LOCALE_CURRENCY_SYMBOL) ? (this.code != LOCALE_CURRENCY) : AMBIGUOUS_CURRENCY_SYMBOLS.has(this.symbol);
    }
  }
}
</script>
