var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    attrs: {
      "name": "Group gift cards",
      "title": "Add gift cards",
      "subtitle": "Your whole group can add to one gift card",
      "action": "View all gift cards",
      "icon": _vm.mdiWalletGiftcard,
      "to": {
        name: 'catalog-gift-cards'
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticClass: "li-pop font-weight-bold ec-dark-blue--text",
    attrs: {
      "to": {
        name: 'catalog-gift-cards'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiWalletGiftcard)
    }
  }), _c('div', [_vm._v("All gift cards")]), _c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiChevronRight)
    }
  })], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "root-grid"
  }, [_c('div', [_c('div', {
    staticClass: "caption pt-3 px-3"
  }, [_vm._v("Popular gift cards")]), _c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.MERCHANTS, function (_ref) {
    var name = _ref[0],
      providerId = _ref[1],
      img = _ref[2];
    return _c('v-list-item', {
      key: name,
      staticClass: "li-pop ec-dark-blue--text",
      attrs: {
        "to": {
          name: 'catalog-gift-cards',
          params: {
            providerId
          }
        }
      }
    }, [_c('v-img', {
      staticClass: "v-card v-sheet ml-n2",
      attrs: {
        "width": "48",
        "max-width": "48",
        "aspect-ratio": 300 / 190,
        "src": img
      }
    }), _c('div', {
      domProps: {
        "textContent": _vm._s(name)
      }
    }), _c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(_vm.mdiChevronRight)
      }
    })], 1);
  }), 1)], 1), _c('div', [_c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.FEATURES, function (_ref2) {
    var name = _ref2[0],
      icon = _ref2[1],
      route = _ref2[2];
    return _c('v-list-item', {
      key: name,
      staticClass: "li-pop font-weight-bold ec-dark-blue--text",
      attrs: {
        "to": route
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _c('div', {
      domProps: {
        "textContent": _vm._s(name)
      }
    }), _c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(_vm.mdiChevronRight)
      }
    })], 1);
  }), 1), false ? _c('div', {
    staticClass: "pa-3"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("Search all gift cards")]), _c('v-text-field', {
    staticClass: "mt-2 white",
    attrs: {
      "label": "Search",
      "outlined": "",
      "dense": "",
      "rounded": "",
      "hide-details": "",
      "multiple": "",
      "single-line": "",
      "clearable": "",
      "readonly": "",
      "append-icon": _vm.mdiMagnify
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'catalog-gift-cards'
        });
      }
    }
  })], 1) : _vm._e()], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }