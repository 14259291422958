import { render, staticRenderFns } from "./GroupVideoBlob.vue?vue&type=template&id=47520c72&scoped=true"
import script from "./GroupVideoBlob.vue?vue&type=script&lang=js"
export * from "./GroupVideoBlob.vue?vue&type=script&lang=js"
import style0 from "./GroupVideoBlob.vue?vue&type=style&index=0&id=47520c72&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47520c72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VImg})
