// A super lightweight directive for watching resize on an element
// Usage: v-el-resize="onResize"
// The 'onResize' method will be called with an object containing the 'target' element along with its current 'width'
//   and 'height'
// By default, the updates are delivered synchronously. If you intend to use the callback to adjust the size of the
//   target element, you should use the 'async' modifier which will wrap the callback using 'requestAnimationFrame' to
//   avoid the "ResizeObserver loop completed with undelivered notifications" error.

export default {
  inserted(el, binding) {
    if (!binding.value)
      return;
    const callback = binding.modifiers.async ? (v => requestAnimationFrame(() => binding.value(v))) : binding.value;
    el._resizeObserver = new ResizeObserver(
      entries => {
        for (const { contentRect } of entries) {
          const { width, height } = contentRect;
          const last = el._resizeObserver._last;
          if (!last || (width != last.width) || (height != last.height)) {
            el._resizeObserver._last = { width, height };
            callback({ target: el, width, height });
          }
        }
      }
    );
    el._resizeObserver.observe(el);
  },

  unbind(el, binding) {
    el._resizeObserver?.unobserve(el);
  }
};
