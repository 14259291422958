// Utilities for managing group videos
import { blankDesignId, nullCoverDesignId, nullCoverDesign } from '@/mixins/public-design.js';
import uuid4 from '@/utils/uuid.js';
import constraints from '@/card-geometry-constraints.js';


const { W, H } = constraints.page;


// The default text for the blob that accompanies any group video
const DEFAULT_GROUP_VIDEO_TEXT = 'We made you a video!';


function insertGroupVideo(order, user_id, template_id, template, project_id) {
  const page = {
    id: uuid4(),
    blobs: [
      {
        id: uuid4(),
        user_id,
        x: W / 2,
        y: 420,
        w: 480,
        h: 270,
        r: 0,
        type: 'GROUP_VIDEO',
        group_video: {
          project_id,
          template_id,
          poster: template.poster,
          frame: 'paper'
        }
      }
    ]
  };
  const insertPageI = (order.design_id == nullCoverDesignId) ? 0 : 1;
  order.pages.splice(insertPageI, 0, page);
  if (template.max_participants != 1)
    order.type = 'GROUP';
}


function _findGroupVideoBlob(order, pageIndex) {
  for (let i = 0; i < order.pages.length; i++)
    for (const blob of order.pages[i].blobs)
      if (blob.type == 'GROUP_VIDEO')
        return pageIndex ? i : blob;
  return null;
}


function findGroupVideoBlob(order) {
  return _findGroupVideoBlob(order, false);
}


function findGroupVideoBlobPageI(order) {
  return _findGroupVideoBlob(order, true);
}


function removeGroupVideoText(page) {
  // Returns the index of the removed blob (if there was one)
  for (let i = page.blobs.length - 1; i >= 0; i--)
    if (page.blobs[i].type == 'TEXT' && page.blobs[i].text.text == DEFAULT_GROUP_VIDEO_TEXT)
      return page.blobs.splice(i, 1) && i;
}


function removeGroupVideo(order) {
  // Make sure to remove not only the GROUP_VIDEO blob itself, but also any default TEXT blob associated with it (on
  // the same page and has the DEFAULT_GROUP_VIDEO_TEXT)
  if (!order)
    return;
  for (const page of order.pages) {
    let found = false;
    for (let i = page.blobs.length - 1; i >= 0; i--) {
      if (page.blobs[i].type == 'GROUP_VIDEO') {
        page.blobs.splice(i, 1);
        found = true;
        removeGroupVideoText(page);
        break;
      }
    }
    if (found)
      break;
  }

  // Make sure we're not left with two blank pages at the end
  // while (
  //   order.pages.length > 1 &&
  //   order.pages[order.pages.length - 1].blobs.length == 0 &&
  //   order.pages[order.pages.length - 2].blobs.length == 0
  // )
  //   order.pages.pop();
}


function getOrderType(order) {
  // Returns a human-readable order type
  // This function is included here since it requires specific knowledge about group videos
  if (order.design_id == nullCoverDesignId)
    return 'video';
  if (findGroupVideoBlob(order))
    return 'card / video';
  return 'card';
}


export { insertGroupVideo, findGroupVideoBlob, findGroupVideoBlobPageI, removeGroupVideo, removeGroupVideoText, getOrderType };
