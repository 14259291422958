// Use with all 'a' or 'v-btn' elements where the href starts with 'mailto:' or 'sms:'
export default {
  inserted(root, binding, vnode) {
    // https://www.uncinc.nl/en/news/dealing-with-mailto-links-if-no-mail-client-is-available
    // When someone clicks on a 'mailto' or 'sms' link, we expect the browser window to blur. If it doesn't, we can
    // infer that the user does not have a client set up.
    const handler = async e => {
      let timeout, onBlur;
      const didBlur = await new Promise(r => {
        onBlur = () => r(true);
        timeout = setTimeout(() => r(false), 2000);
        addEventListener('blur', onBlur);
      });
      clearTimeout(timeout);
      removeEventListener('blur', onBlur);
      if (!didBlur) {
        const url = new URL(root.href);
        const protocol = { 'mailto:': [ 'an', 'email ' ], 'sms:': [ 'a', 'text messaging ' ] }[url.protocol];
        const [ article, client ] = protocol || [ 'a', '' ];
        const p = url.pathname;
        const s = url.searchParams.get('subject');
        const b = url.searchParams.get('body');
        vnode.componentInstance.$confirm({
          title: `No ${client} client available`,
          msg: `It looks like your browser does not have ${article} ${client}client configured. Please copy / paste the following into your ${client}client manually:${p ? '<br><br>' + p : ''}${s ? '<br><br>' + s : ''}${b ? '<br><br>' + b : ''}`,
          buttons: [ 'Done '],
          primary: 'Done'
        });
      }
    }
    root.addEventListener('click', handler);
    root.addEventListener('keydown', e => (e.key == 'Enter') && handler(e));
  }
};
