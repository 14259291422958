<template>
  <div v-if="frame" class="absolute-fill frame" v-bind:class="[ frame, type ]">

    <div v-if="!only || (only == 'background')" class="absolute-fill background" v-html="svgBackground" />

    <div class="absolute-fill blob" v-bind:class="{ 'overflow-hidden': hasCrop }" v-bind:style="{ 'mask-image': mask }">
      <slot></slot>
    </div>

    <template v-if="!only || (only == 'foreground')">
      <div v-if="svgForeground" class="absolute-fill foreground" v-html="svgForeground" />
      <div v-else class="absolute-fill foreground">
        <div class="edge left" />
        <div class="edge right" />
        <div class="edge top" />
        <div class="edge bottom" />
        <div class="corner top-left" />
        <div class="corner top-right" />
        <div class="corner bottom-left" />
        <div class="corner bottom-right" />
      </div>
    </template>

  </div>

  <!-- Warning: the 'click' handler attached to the 'envelope' in the order-stepper-theme component relies on this HTML structure to extract the blob ID from click events, so make sure that if you change / simplify the HTML structure within a blob that you also update that logic -->
  <div v-else class="absolute-fill blob" v-bind:class="{ 'overflow-hidden': hasCrop }">
    <slot></slot>
  </div>
</template>


<style scoped lang="scss">
$edge-thickness: 32px;

.frame {
  .background {
    z-index: 1;
  }
  .blob {
    z-index: 2;
  }
  .foreground {
    z-index: 3;
  }

  &.TEXT {
    .background, .foreground {
      transform: scale(1.08);
    }
  }

  .background, .foreground {
    pointer-events: none;

    .edge, .corner {
      display: none;
      position: absolute;
      pointer-events: auto;
    }

    .edge {
      &.top { width: 100%; height: $edge-thickness; }
      &.left { top: $edge-thickness; width: $edge-thickness; height: calc(100% - #{$edge-thickness * 2}); }
      &.right { top: $edge-thickness; right: 0; width: $edge-thickness; height: calc(100% - #{$edge-thickness * 2}); }
      &.bottom { bottom: 0; width: 100%; height: $edge-thickness; }
    }

    .corner {
      &.top-right { right: 0; }
      &.bottom-left { bottom: 0; }
      &.bottom-right { bottom: 0; right: 0; }
    }
  }
}

.frame.wood {
  .background {
    display: block;
    background-color: #28241C;
  }

  .foreground {
    display: block;
    margin: -$edge-thickness;

    .edge {
      display: block;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);
      background-color: #333623;
      background-repeat: repeat;

      &.top, &.bottom { background-image: url('//ellacard.com/frames/wood-horizontal.png'); }
      &.left, &.right { background-image: url('//ellacard.com/frames/wood-vertical.png'); }
    }
  }
}

.frame.glass {
  .background {
    display: block;
    background-color: rgba(237, 246, 252, .3);
    backdrop-filter: blur(5px);
  }

  .foreground {
    display: block;
    margin: -$edge-thickness;

    .edge {
      display: block;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);
      background-color: rgba(237, 246, 252, .3);
      backdrop-filter: blur(5px);

      &.top { border-top-left-radius: 16px; border-top-right-radius: 16px; }
      &.bottom { border-bottom-left-radius: 16px; border-bottom-right-radius: 16px; }
    }
  }
}

.frame.gold {
  .background {
    display: block;
    background-color: #d4af37;
  }

  .foreground {
    display: block;
    margin: -$edge-thickness;

    .edge {
      display: block;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);
      background-color: #d4af37;
      background-image: url('//ellacard.com/frames/concrete-wall.png');
      background-repeat: repeat;
    }
  }
}

.frame.tape {
  .background {
    display: block;
    margin: -12px;
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
  }

  .foreground {
    display: block;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .2);

    .corner {
      display: block;
      background-color: rgba(235, 235, 235, .5);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
      width: min(64px, 20%);
      height: min(156px, 48%);

      &.top-left { transform: translate(-50%, -50%) rotate(45deg); }
      &.top-right { transform: translate(50%, -50%) rotate(-45deg); }
      &.bottom-left { transform: translate(-50%, 50%) rotate(-45deg); }
      &.bottom-right { transform: translate(50%, 50%) rotate(45deg); }
    }
  }
}


.frame.metal {
  .background {
    display: block;
    background-color: #999;
    background-image: url('//ellacard.com/frames/concrete-wall.png');
    background-repeat: repeat;
  }

  .foreground {
    display: block;

    .corner {
      display: block;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);
      background-image: url('//ellacard.com/frames/metal-frame-corner.png');
      background-size: contain;
      width: 32px;
      height: 32px;

      &.top-left { transform: translate(-87.5%, -87.5%); }
      &.top-right { transform: translate(87.5%, -87.5%); }
      &.bottom-left { transform: translate(-87.5%, 87.5%); }
      &.bottom-right { transform: translate(87.5%, 87.5%); }
    }

    .edge {
      display: block;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);
      background-size: contain;
      background-repeat: repeat;

      &.top, &.bottom { background-image: url('//ellacard.com/frames/metal-frame-edge-horizontal.png'); }
      &.left, &.right { background-image: url('//ellacard.com/frames/metal-frame-edge-vertical.png'); }

      &.top { transform: translate(0, -100%); height: 24px; }
      &.left { transform: translate(-100%, 0); top: 0; height: 100%; width: 24px; }
      &.right { transform: translate(100%, 0); top: 0; height: 100%; width: 24px; }
      &.bottom { transform: translate(0, 100%); height: 24px; }
    }
  }
}

.frame.paper {
  .background {
    display: block;
    margin: -8px;
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
  }

  .foreground {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .2);
  }
}

.frame.tacks {
  .background {
    display: block;
    margin: -8px;
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
  }

  .foreground {
    display: block;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .2);

    .corner {
      display: block;
      background-size: cover;
      width: 64px;
      height: 64px;

      &.top-left { transform: translate(-25%, -25%); background-image: url('//ellacard.com/frames/thumbtack-blue.png'); }
      &.top-right { transform: translate(25%, -25%); background-image: url('//ellacard.com/frames/thumbtack-red.png'); }
      &.bottom-left { transform: translate(-25%, 25%); background-image: url('//ellacard.com/frames/thumbtack-green.png'); }
      &.bottom-right { transform: translate(25%, 25%); background-image: url('//ellacard.com/frames/thumbtack-yellow.png'); }
    }
  }
}

// NOTE: be careful to account for blobs with transparency when adding new frames
</style>


<script>
import { BUBBLE_FRAMES, buildBubbleFrameSVG } from '@/utils/bubble-frames.js';

export default {
  name: 'blob-frame',

  props: [ 'frame', 'only', 'options', 'type', 'isSelected', 'hasCrop' ],

  created() {
    Object.assign(this, { buildBubbleFrameSVG });
  },

  computed: {
    bubble() {
      return BUBBLE_FRAMES.find(x => x.key == this.frame);
    },

    svgBackground() {
      if (!this.bubble)
        return null;
      return buildBubbleFrameSVG(this.bubble, this.options, this.type);
    },

    mask() {
      if (!this.bubble || !this.type || (this.type == 'TEXT') || !this.options)
        return null;
      const svg = this.options.shadow ? buildBubbleFrameSVG(this.bubble, { ...this.options, shadow: 0 }, this.type) : this.svgBackground;
      return `url("data:image/svg+xml;base64,${btoa(svg)}")`;
    },

    svgForeground() {
      if (!this.bubble || !this.type || (this.type == 'TEXT') || !this.options?.stroke)
        return null;
      return buildBubbleFrameSVG(this.bubble, { ...this.options, fill: '#0000', shadow: 0 }, this.type);
    }
  }
}
</script>
