const RESERVED_TITLES = new Set([ 'Name', 'Can you make it?', 'Adults', 'Children', 'Attending', 'Attendees', 'Total number of attendees' ]);

function getFixedInputs({ n_rsvp, separate_counts, request_names }) {
  const inputs = [
    {
      // key: 'ATTENDING',
      type: 'RADIO',
      title: 'Can you make it?',
      lock: [ 'move', 'title', 'subtitle', 'required', 'choices' ],
      root: true,
      required: true,
      row: true,
      choices: [ 'Yes', 'No' ]
    }
  ];
  if (n_rsvp > 1) {
    if (request_names)
      inputs.push({
        // key: 'ATTENDEE_NAMES',
        type: 'ATTENDEE_NAMES',
        title: 'Attendees',
        subtitle: 'List the names of those in your party (including yourself)' + (separate_counts ? ' and please indicate any children' : ''),
        lock: [ 'move', 'title', 'subtitle', 'required', 'delete' ],
        public: true,
        conditional: true,
        required: true,
        separate_counts,
        min: 1,
        max: n_rsvp
      });
    else if (separate_counts)
      inputs.push({
        // key: 'ATTENDEE_SEPARATE_COUNTS',
        type: 'ATTENDEE_SEPARATE_COUNTS',
        title: 'Total number of attendees',
        subtitle: 'Including yourself',
        lock: [ 'move', 'title', 'subtitle', 'conditional', 'required', 'delete', 'range', 'public' ],
        public: true,
        conditional: true,
        required: true,
        min: 1,
        max: n_rsvp
      });
    else
      inputs.push({
        // key: 'ATTENDEE_N',
        type: 'INT',
        title: 'Total number of attendees',
        subtitle: 'Including yourself',
        lock: [ 'move', 'title', 'subtitle', 'conditional', 'required', 'delete', 'range', 'public' ],
        public: true,
        conditional: true,
        required: true,
        min: 1,
        max: n_rsvp
      });
  }
  return inputs;
}


function patchFormInputs(form) {
  if (!form.inputs.find(x => x.root))
    // There's no root, so don't alter the inputs at all
    // return form.inputs.map(x => Object.assign(fixed.find(y => x.title == y.title) || {}, x));
    return form.inputs;

  // The form must always start with these fixed inputs (the settings of which are configurable however)
  const fixed = getFixedInputs(form);
  const remaining = form.inputs.filter(x => !RESERVED_TITLES.has(x.title));
  for (const x of fixed) {
    Object.assign(x, form.inputs.find(y => x.title == y.title) || {});
    if (x.title == 'Total number of attendees')
      x.type = form.separate_counts ? 'ATTENDEE_SEPARATE_COUNTS' : 'INT';
  }
  return [ ...fixed, ...remaining ];
}


function patchResponse(form, response) {
  // Return a response that has the correct structure for the provided 'form' options for use with the form-rsvp
  // component
  const patched = _.cloneDeep(response);
  const { request_names, n_rsvp, separate_counts } = form;
  const updates = {};
  if (request_names) {
    if (n_rsvp > 1) {
      if (!Array.isArray(patched['Attendees']))
        // The 'Attendees' field will be showing, and it's not currently in the right format (possibly was used to
        // answer another questions that previously had the name "Attendees" or more likely just doesn't exist
        // yet)
        patched['Attendees'] = [ { name: '' } ];
      for (const x of patched['Attendees']) {
        if (!x.hasOwnProperty('name'))
          x.name = '';
        if (separate_counts && !x.hasOwnProperty('type'))
          x.type = 'adult';
      }
    }
    delete patched['Total number of attendees'];
  } else {
    if (separate_counts && !_.isObject(patched['Total number of attendees'])) {
      patched['Total number of attendees'] = { adult: 0, child: 0 };
    } else if (!separate_counts && _.isObject(patched['Total number of attendees'])) {
      const { adult, child } = patched['Total number of attendees'];
      patched['Total number of attendees'] = (adult || 0) + (child || 0);
    }
    if (!patched.hasOwnProperty('Total number of attendees') && patched.hasOwnProperty('Attendees'))
      patched['Total number of attendees'] = patched['Attendees'].length;
  }
  return patched;
}


export { getFixedInputs, patchFormInputs, patchResponse };
