class LRUCache {
  constructor(maxSize) {
    this.maxSize = maxSize;
    this.cache = new Map();
  }

  get(key) {
    let item = this.cache.get(key);
    if (item) {
      // This effectively sets the last-used time to now
      this.cache.delete(key);
      this.cache.set(key, item);
    }
    return item;
  }

  set(key, val) {
    if (this.cache.has(key))
      this.cache.delete(key);
    else if (this.cache.size == this.maxSize)
      this.cache.delete(this.cache.keys().next().value);
    this.cache.set(key, val);
  }

  evict(key) {
    return this.cache.delete(key);
  }
}


export default LRUCache;
