import loadFont from '@/utils/load-font.js';
import LRUCache from '@/utils/lru-cache.js';


// Make SURE this code matches ellacard-video-service:app/ev_render_video.py


// How much padding to surround the text with. Note that we apply this in all cases, not just when the media has a
// background color, in order to give us a buffer for characters that might overlap the bounds, like the end of a line
// with an italicized character.
const OUTLINE_EM = .5;


// function measureSpaceWidth(size, font) {
//   const canvas = measureSpaceWidth.canvas || (measureSpaceWidth.canvas = document.createElement('canvas'));
//   const ctx = canvas.getContext('2d');
//   ctx.font = `400 ${size}px ${font}`;
//   return ctx.measureText(' ').width;
// }

function resizeTextEl(el, media, outline=OUTLINE_EM, ignoreAddtlCSS=false, binarySearch=false) {
  el.classList.add('renderable-text');
  el.classList[ media.bold ? 'add' : 'remove' ]('pseudo-bold');
  el.style.display = 'table-cell';
  el.style.textAlign = media.justify || 'center';
  el.style.verticalAlign = !media.align || media.align == 'center' ? 'middle' : media.align;
  el.style.fontFamily = media.font;
  el.style.color = media.color;

  if (!ignoreAddtlCSS) {
    el.style.fontStyle = media.italic ? 'italic' : null;
    el.style.textDecoration = media.underline ? 'underline' : null;
    el.style.textShadow = media.shadow || null;
  }

  // Note that 'scrollWidth' and 'scrollHeight' always return integers
  const w = Math.floor(media.size.w);
  const h = Math.floor(media.size.h);

  el.style.width = el.style.maxWidth = w + 'px';

  let max = media.font_size;
  let min = media.min_font_size;

  let fontSize;
  try {
    fontSize = Math.min(max, parseFloat(getComputedStyle(el, null).getPropertyValue('font-size').slice(0, -2)));
  } catch (e) {
    fontSize = max;
  }

  const isTooTall = () => el.scrollHeight > h - fontSize * outline * 2;
  const isTooWide = () => el.scrollWidth > w - fontSize * outline * 2;
  const update = () => {
    el.style.fontSize = fontSize + 'px';
    el.style.width = el.style.maxWidth = (w - fontSize * outline * 2) + 'px';
  }

  // Note that we use two different approaches to compute the optimal text size: binary search and incremental. Both
  // yield correct results. The former is generally faster, but the user will see the font size jumping around instead
  // of smoothly adjusting to its optimal size, and when resizing a blob, the latter approach is generally faster
  // anyway since the font size barely changes. We use the scroll height ratio as a rough proxy to determine if we're
  // already close to the desired font size (and thus we should use the incremental approach).

  if (binarySearch) {
    // Binary search approach
    // This approach is best when current fontSize may be wildly different from resulting fontSize, e.g. when a blob
    // first loads)
    fontSize = Math.floor((min + max) / 2);
    while ((fontSize > min) && (max - min > 1)) {
      update();
      if (isTooTall() || isTooWide()) {
        max = fontSize - 1;
        fontSize = Math.floor((fontSize + min) / 2);
      } else {
        min = fontSize;
        fontSize = Math.floor((fontSize + max) / 2);
      }
    }
    fontSize = min;
    update();
  }

  // Incremental approach
  // This approach is best when the current fontSize is very close to being correct, e.g. when we are resizing a
  // blob. It also has the advantage of resizing smoothly (the fontSize will not jump around as we search to find the
  // best fit).
  update();
  while ((fontSize < max) && !isTooTall() && !isTooWide()) {
    fontSize++;
    update();
  }
  while ((fontSize > min) && (isTooTall() || isTooWide())) {
    fontSize--;
    update();
  }

  return fontSize;
}


async function nodeToPNG(node, w, h) {
  // Warning: this mutates the node
  node.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
  const nodeStr = new XMLSerializer().serializeToString(node).replace(/#/g, '%23').replace(/\n/g, '%0A');
  const svg = `data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="${w}" height="${h}"><foreignObject x="0" y="0" width="100%" height="100%">${nodeStr}</foreignObject></svg>`;
  const img = new Image();
  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
    img.src = svg;
  });
  await new Promise(resolve => setTimeout(resolve, 100));
  const canvas = document.createElement('canvas');
  canvas.width = w;
  canvas.height = h;
  canvas.getContext('2d').drawImage(img, 0, 0);
  return canvas.toDataURL();
}


const URL_REGEX = /url\(['"]?([^'"]+?)['"]?\)/g;


async function buildTextImgSrcKernel(media) {
  // We need to load the remote web font AND in-line it so it can be used in an SVG ('nodeToPNG' uses an SVG internally)
  const url = await loadFont(media.font);
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(`@font-face { font-family: ${media.font}; src: url(${url}); }`));

  const container = document.createElement('div');
  container.style.width = media.size.w + 'px';

  const div = document.createElement('div');
  const span = document.createElement('span');
  span.innerText = media.text;
  span.style.background = media.background || 'transparent';
  span.style.outline = `${OUTLINE_EM}em solid ${media.background || 'transparent'}`;
  container.appendChild(style);
  container.appendChild(div);
  div.appendChild(span);

  // Note: it's important for text resizing that the container is actually part of the DOM
  document.body.appendChild(container);

  const fontSize = resizeTextEl(div, media, OUTLINE_EM, true, true);
  div.style.height = (media.size.h - fontSize * OUTLINE_EM * 2) + 'px';
  container.style.height = media.size.h + 'px';
  container.style.padding = Math.floor(fontSize * OUTLINE_EM) + 'px';

  // Note: I experimented with using dom-to-image here, but it's ultimately overkill and has a few issues, namely it
  // makes remote calls to load EVERY font in the document, when we know we only need the ONE in question. We also
  // don't need to support images, and I'm always in favor of removing dependencies...
  const png = await nodeToPNG(container, media.size.w, media.size.h);
  container.remove();
  return png;
}

const cache = new LRUCache(64);

async function buildTextImgSrc(media) {
  // Given a TEXT media element, this function will render the text into a PNG image using the appropriate font / style
  // and return the result as a data URL. It is cached.
  const key = JSON.stringify(media);
  let src = cache.get(key);
  if (src)
    return src;
  src = await buildTextImgSrcKernel(media);
  cache.set(key, src);
  return src;
}


export { OUTLINE_EM, resizeTextEl, buildTextImgSrc };
