<template>
  <!-- The default Vuetify snackbar may run into issues in iOS depending on where it gets displayed, possibly related to the fixed positioning. Wrapping the snackbar in a v-dialog puts it at the top level of the app DOM, thus avoiding the problem. We also get slightly better UX in that the snackbar will hide on any click. Note that a non-dismissable snackbar (one that has an automatic timeout) will prevent all user interaction until its model is updated. -->
  <v-dialog
    :content-class="isDismissable ? 'snackbar-dismissable' : ''"
    eager fullscreen :transition="false" :persistent="!isDismissable"
    :value="value"
    @input="x => $emit('input', x)"
  >
    <v-snackbar
      v-bind="$attrs"
      :value="value"
      @input="x => $emit('input', x)"
      @click.native="isDismissable && $emit('input', false)"
    >
      <slot></slot>
    </v-snackbar>
  </v-dialog>
</template>

<style>
.snackbar-dismissable {
  pointer-events: none;
}
</style>

<script>
export default {
  name: 'snackbar',
  props: [ 'value' ],
  computed: {
    isDismissable() {
      return String(this.$attrs.timeout) != '-1';
    }
  }
}
</script>
