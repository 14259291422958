// When navigating to new routes where we only update one part of the route, we can't necessarily just pass a partial
// route (i.e. update the hash only). We can't simply use the pattern { ...$route, hash: '#something' }, since '$route'
// might be a recursive object. This function allows us to easily extend the current route by extracting only the
// relevant parameters.

export default {
  methods: {
    $routeAssign(updated) {
      return Object.assign(_.pick(this.$route, [ 'name', 'params', 'hash', 'query' ]), updated);
    }
  }
};
