<template>
  <div :key="blob.group_video.project_id" class="absolute-fill">
    <template v-if="blob.group_video.img_src">
      <div v-if="placeholder" class="absolute-fill placeholder" />
      <img class="user-select-none" :src="blob.group_video.img_src" @load="onLoad" draggable="false">
    </template>
    <v-card v-else class="absolute-fill" dark tile>
      <v-img v-if="mode == 'preview'" :aspect-ratio="16 / 9" :src="blob.group_video.poster" />
    </v-card>
  </div>
</template>

<style scoped>
img {
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.placeholder {
  box-sizing: border-box;
  border: 4px dashed rgba(0, 0, 0, .1);
  border-radius: 8px;
}
</style>

<script>
export default {
  name: 'group-video-blob',

  props: [ 'blob', 'mode' ],

  data() {
    return {
      placeholder: true
    };
  },

  methods: {
    onLoad() {
      this.placeholder = false;
      this.$emit('loaded');
    }
  },

  watch: {
    'blob.group_video.img_src': {
      immediate: true,
      handler(v) {
        if (!v)
          this.$emit('loaded');
      }
    }
  }
}
</script>
