import Vue from 'vue';

window.SSR_CACHE ||= {};

const SSR_COMPONENTS = [];
const CALLBACKS = [];

export async function waitHydrated() {
  await new Promise(async r => {
    await Vue.nextTick();
    const callback = () => {
      if (SSR_COMPONENTS.every(x => x.mountedAndHydrated)) {
        CALLBACKS.splice(CALLBACKS.indexOf(callback), 1);
        r();
      }
    }
    CALLBACKS.push(callback);
    callback();
  });
  if (window.SSR_WRITE)
    writeCache();
}

// Use this as the second argument to JSON.stringify to recursively preserve the sort order of the object keys
const inOrder = (key, value) => {
  if ((value instanceof Object) && !(value instanceof Array))
    return Object.keys(value).sort().reduce(
      (sorted, key) => {
        sorted[key] = value[key];
        return sorted;
      },
      {}
    )
  return value;
}

export function cache(fn) {
  return async function() {
    const key = JSON.stringify([ fn.name, arguments ], inOrder);
    if (window.SSR_WRITE) {
      try {
        const r = await fn(...arguments);
        SSR_CACHE[key] = { r };
        return r;
      } catch (e) {
        const { name, message, ...props } = e;
        SSR_CACHE[key] = { e: { cls: e.constructor.name, name, message, props } };
        throw e;
      }
    }
    if (SSR_CACHE.hasOwnProperty(key)) {
      const { r, e } = SSR_CACHE[key];
      delete SSR_CACHE[key];
      if (e) {
        eval(`const err = new ${e.cls}(${JSON.stringify(e.message)});`)
        err.name = e.name;
        for (const [ key, value ] of Object.entries(e.props))
          err[key] = value;
        throw err;
      }
      return r;
    }
    return await fn(...arguments);
  }
}

function writeCache() {
  document.getElementById('ssr-cache')?.remove();
  const el = document.createElement('script');
  el.setAttribute('id', 'ssr-cache');
  el.innerHTML = `window.SSR_CACHE = ${JSON.stringify(SSR_CACHE)}`;
  document.head.appendChild(el);
}

function onUpdate() {
  CALLBACKS.map(x => x());
}

export const ssr = {

  data() {
    return {
      mounted: false
    };
  },

  computed: {
    mountedAndHydrated() {
      return !!(this.mounted && this.hydrated);
    }
  },

  created() {
    SSR_COMPONENTS.push(this);
    onUpdate();
  },

  mounted() {
    this.mounted = true;
  },

  unmounted() {
    this.mounted = false;
  },

  destroyed() {
    SSR_COMPONENTS.splice(SSR_COMPONENTS.indexOf(this), 1);
    onUpdate();
  },

  watch: {
    mountedAndHydrated: {
      immediate: true,
      handler(v) {
        onUpdate();
      }
    }
  }
};
