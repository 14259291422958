var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createOrg.apply(null, arguments);
      }
    },
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('error-dialog', {
    attrs: {
      "status": _vm.loadAccountStatus
    }
  }), _c('error-dialog', {
    attrs: {
      "status": _vm.createOrgStatus
    }
  }), _c('sign-in-dialog', {
    scopedSlots: _vm._u([{
      key: "subtitle",
      fn: function () {
        return [_vm._v("Sign in to verify your email address")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showSignIn,
      callback: function ($$v) {
        _vm.showSignIn = $$v;
      },
      expression: "showSignIn"
    }
  }), !_vm.hideEmail ? _c('v-text-field', {
    staticClass: "mb-6",
    attrs: {
      "value": _vm.isSignedIn ? _vm.global.profile.email : null,
      "name": "Email address",
      "label": "Email address",
      "readonly": "",
      "error-messages": _vm.accountErr
    },
    on: {
      "click": function ($event) {
        _vm.showSignIn = true;
      }
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "loading": _vm.loadAccountStatus.pending
          },
          domProps: {
            "textContent": _vm._s(_vm.isSignedIn ? 'Change…' : 'Sign in…')
          },
          on: {
            "click": function ($event) {
              _vm.showSignIn = true;
            }
          }
        })];
      },
      proxy: true
    }], null, false, 3556598503)
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "name": "your name",
      "label": "Your name",
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.customerName,
      callback: function ($$v) {
        _vm.customerName = $$v;
      },
      expression: "customerName"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "name": "Organization name",
      "label": "Organization name",
      "disabled": _vm.isDisabled,
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.orgName,
      callback: function ($$v) {
        _vm.orgName = $$v;
      },
      expression: "orgName"
    }
  }), _c('v-textarea', {
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "Please describe your use case",
      "auto-grow": "",
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.notes,
      callback: function ($$v) {
        _vm.notes = $$v;
      },
      expression: "notes"
    }
  }), _c('div', {
    staticClass: "mt-1 text--label-active"
  }, [_vm._v("Ellacard has powerful tools to support your use case – we'll point you in the right direction to help you get started")]), _c('div', {
    staticClass: "mt-6 d-flex"
  }, [_c('v-spacer'), !_vm.hideCancel ? _c('v-btn', {
    staticClass: "mr-4",
    on: {
      "click": function ($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v("Cancel")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.isDisabled,
      "loading": _vm.createOrgStatus.pending
    },
    on: {
      "click": function ($event) {
        _vm.$refs.form.validate() && _vm.createOrg();
      }
    }
  }, [_vm._v(" Submit ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }