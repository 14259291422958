import { LOCALE, TZ } from '@/utils/intl.js';
import { getTimezoneName } from '@/utils/time.js';


function asDate(v, opts, truncateMinutes, locale=LOCALE) {
  if (!v)
    return null;
  if (/^\d{4}-\d{2}-\d{2}$/.test(v))
    v = v + ' 00:00:00';
  if (/^\d{2}:\d{2}(:\d\d(\.\d+)?)?$/.test(v))
    v = '2020-01-01 ' + v;
  const d = new Date(v);
  if (truncateMinutes && !parseInt(d.toLocaleString(locale, { hour12: false, minute: '2-digit', timeZone: TZ })))
    return d.toLocaleString(locale, { ...opts, minute: undefined });
  return d.toLocaleString(locale, opts);
}

function getOrdinal(n) {
  if (n > 3 && n < 21)
    return 'th';
  switch (n % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

function getTimezoneDate(e) {
  return (e.date_start && e.time_start) ? new Date(e.date_start + ' ' + e.time_start) : new Date();
}

const FORMATTERS = {
  name: [
    [ 'name' ],
    e => e.name
  ],
  from: [
    [ 'date_start', 'time_start' ],
    e => asDate(e.date_start + ' ' + e.time_start, { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' }, true)
  ],
  from_weekday: [
    [ 'date_start' ],
    e => asDate(e.date_start, { weekday: 'long' })
  ],
  from_weekday_abbr: [
    [ 'date_start' ],
    e => asDate(e.date_start, { weekday: 'short' })
  ],
  from_month: [
    [ 'date_start' ],
    e => asDate(e.date_start, { month: 'long' })
  ],
  from_month_abbr: [
    [ 'date_start' ],
    e => asDate(e.date_start, { month: 'short' })
  ],
  from_day: [
    [ 'date_start' ],
    e => asDate(e.date_start, { day: 'numeric' })
  ],
  from_day_en_ordinal: [
    [ 'date_start' ],
    e => {
      const n = asDate(e.date_start, { day: 'numeric' });
      return n && (n + '<sup>' + getOrdinal(parseInt(n)) + '</sup>');
    }
  ],
  from_date: [
    [ 'date_start' ],
    e => asDate(e.date_start, { month: 'long', day: 'numeric' })
  ],
  from_date_en_ordinal: [
    [ 'date_start' ],
    e => e.date_start && (asDate(e.date_start, { month: 'long', day: 'numeric' }, false, 'en-US') + '<sup>' + getOrdinal(parseInt(asDate(e.date_start, { day: 'numeric', timeZone: e.tz || TZ }))) + '</sup>')
  ],
  from_date_year: [
    [ 'date_start' ],
    e => asDate(e.date_start, { dateStyle: 'long' })
  ],
  from_date_numeric: [
    [ 'date_start' ],
    e => asDate(e.date_start, { month: 'numeric', day: 'numeric' })
  ],
  from_date_numeric_year: [
    [ 'date_start' ],
    e => asDate(e.date_start, { dateStyle: 'short' })
  ],
  from_time: [
    [ 'time_start' ],
    e => asDate(e.time_start, { timeStyle: 'short' })
  ],
  from_time_truncate_minutes: [
    [ 'time_start' ],
    e => asDate(e.time_start, { hour: 'numeric', minute: '2-digit' }, true)
  ],
  to_time: [
    [ 'time_end' ],
    e => asDate(e.time_end, { timeStyle: 'short' })
  ],
  to_time_truncate_minutes: [
    [ 'time_start' ],
    e => asDate(e.time_end, { hour: 'numeric', minute: '2-digit' }, true)
  ],

  tz_long: [
    [],
    e => getTimezoneName(getTimezoneDate(e), 'long', e.tz || TZ, LOCALE)
  ],
  tz_short: [
    [],
    e => getTimezoneName(getTimezoneDate(e), 'short', e.tz || TZ, LOCALE)
  ],
  tz_long_generic: [
    [],
    e => getTimezoneName(getTimezoneDate(e), 'longGeneric', e.tz || TZ, LOCALE)
  ],
  tz_short_generic: [
    [],
    e => getTimezoneName(getTimezoneDate(e), 'shortGeneric', e.tz || TZ, LOCALE)
  ],

  datetime_range: [
    [ 'date_start', 'time_start' ],
    e => {
      if (!e.date_start || !e.time_start)
        return null;
      if (!e.time_end)
        return FORMATTERS.from[1](e);
      const fromTime = FORMATTERS.from_time_truncate_minutes[1](e);
      const toTime = FORMATTERS.to_time_truncate_minutes[1](e);
      if (!/[AP]M$/i.test(fromTime))
        // Our locale does not use AM / PM
        return FORMATTERS.from_date[1](e) + ', ' + fromTime + ' – ' + toTime;
      if (fromTime.slice(-2) == toTime.slice(-2))
        // These times share the same suffix
        return FORMATTERS.from_date[1](e) + ', ' + fromTime.replace(/\s+[AP]M$/, '') + ' – ' + toTime;
      return FORMATTERS.from_date[1](e) + ', ' + fromTime + ' – ' + toTime;
    }
  ],

  time_range: [
    [ 'time_start' ],
    e => {
      if (!e.time_start)
        return null;
      if (!e.time_end)
        return FORMATTERS.from_time_truncate_minutes[1](e);
      const fromTime = FORMATTERS.from_time_truncate_minutes[1](e);
      const toTime = FORMATTERS.to_time_truncate_minutes[1](e);
      if (!/[AP]M$/i.test(fromTime))
        // Our locale does not use AM / PM
        return fromTime + ' – ' + toTime;
      if (fromTime.slice(-2) == toTime.slice(-2))
        // These times share the same suffix
        return fromTime.replace(/\s+[AP]M$/, '') + ' – ' + toTime;
      return fromTime + ' – ' + toTime;
    }
  ],

  location: [
    [ 'location' ],
    e => e.location
  ],
  address: [
    [ 'address' ],
    e => e.address
  ],
  address_single_line: [
    [ 'address' ],
    e => e.address && e.address.split('\n').filter(x => /\S/.test(x)).join(', ')
  ]
};


function getChunks(format) {
  return Array.from(format.matchAll(/[^{]+|{[^}]+}/g)).map(x => x[0]);
}


function formatTextEventLink(event, format) {
  let output = '';
  let hidden = 0;
  for (const c of getChunks(format)) {
    if (c == '{/if}') {
      if (hidden)
        hidden--;
      continue;
    }
    if (c.startsWith('{#if')) {
      const match = c.match(/{#if\s+(\S+)\s*}/);
      if (hidden || !match || !FORMATTERS[ match[1] ] || !FORMATTERS[ match[1] ][1](event))
        hidden++;
      continue;
    }
    if (hidden)
      continue;
    const match = c.match(/^{\s*(\S+)\s*}$/);
    if (match && FORMATTERS[ match[1] ])
      output += FORMATTERS[ match[1] ][1](event) || '';
    else
      output += c;
  }
  return output;
}


function getEventFields(format) {
  const fields = new Set();
  for (const c of getChunks(format || '')) {
    const match = c.match(/{(#if\s+)?([^\/]\S+)\s*}/);
    if (match && FORMATTERS[ match[2] ])
      for (const field of FORMATTERS[ match[2] ][0])
        fields.add(field);
  }
  return fields;
}


function isRequiredEventField(field, order) {
  return order.pages.some(x => x.blobs.some(y => (!y.for_design_id || (y.for_design_id == order.design_id)) && getEventFields(y.text?.event_link?.format).has(field)));
}


function updateEventLinks(order, updatedEvent) {
  for (const page of order.pages) {
    for (const blob of page.blobs) {
      if ((blob.type == 'TEXT') && blob.text.event_link && !blob.text.event_link.disabled) {
        const formatted = formatTextEventLink(updatedEvent, blob.text.event_link.format);
        if (blob.text.text != formatted) {
          // Only update this blob if the formatted text actually changed
          blob.text.text = formatted;
        }
      }
    }
  }
}


export { FORMATTERS, formatTextEventLink, getEventFields, isRequiredEventField, updateEventLinks };
