// import cache from './cache';


// TODO: look into this
// https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort


class OPError extends Error {
  constructor(content) {
    super(content.msg); // Will be accessible via the 'message' property
    this.type = content.err;
  }
}


/**
 * Make a remote function call to an op-dev service
 * endpoint: the path of the API we are calling (like 'usage/engagement/users') - should not start with a '/'
 * data: an object containing the data to pass to the API. Keys whose value is undefined will not be included in the
 *   request.
 */
async function opDevFetch(url, authToken=undefined, data=undefined) {
  // Wait until the page is visible before making any API calls
  if (document.visibilityState != 'visible')
    await new Promise(r => addEventListener('visibilitychange', r, { once: true }));

  const options = { method: 'POST', cache: 'no-store', headers: {} };
  if (!url.startsWith(location.origin))
    options.mode = 'cors';
  if (data) {
    options.body = new FormData();
    const blobs = [];
    function replaceBlobs(key, value) {
      if (value instanceof Blob) {
        const i = blobs.push(value) - 1;
        return { '__file__': String(i) };
      }
      return value;
    }
    const serialized = JSON.stringify(data, replaceBlobs);
    if (blobs.length > 0) {
      options.body.append('_', serialized);
      for (let i = 0; i < blobs.length; i++) {
        options.body.append(i, blobs[i]);
      }
    } else {
      options.headers['content-type'] = 'application/json';
      options.body = serialized;
    }
  }
  if (authToken)
    options.headers['authorization'] = `Bearer ${authToken}`;

  const response = await fetch(encodeURI(url), options);
  if (response.ok) {
    const contentType = response.headers.get('content-type');
    if (contentType == 'application/json') {
      return await response.json();
    } else {
      return await response.blob();
    }
  }

  try {
    const content = await response.json();
    if (content.hasOwnProperty('err') && content.hasOwnProperty('msg')) {
      throw new OPError(content);
    }
  } catch(e) {
    if (e instanceof OPError) {
      throw e;
    }
    throw response;
  }
};

export { opDevFetch, OPError };
