<template>
  <div
    class="absolute-fill back gc-border"
    v-bind:class="{ clickable: mode == 'view' }"
    v-on="mode == 'view' ? { click: () => $emit('click') } : {}"
  >
    <!-- Note that you had been scaling the button here, but that's not really necessary since the size of the gift cards themselves is fairly constrained and avoids inconsistencies: v-bind:style="{ transform: `scale(${1 / scale})` }" -->
    <div class="absolute">
      <v-btn
        v-blur
        class="mt-n5 ml-n5"
        style="transition: unset;"
        v-bind:style="{
          'cursor': mode == 'preview' ? null : 'pointer',
          'pointer-events': mode == 'preview' ? null : 'auto',
          'transform': `rotate(${-blob.r}deg)`
        }"
        rounded dark
        :color="blob.giftcard.claim_id ? 'grey lighten-1' : 'green lighten-2'"
        v-on="(mode == 'preview') || !showValue ? {} : { click: () => $emit('click') }"
      >
        <currency :code="blob.giftcard.cur" />{{showValue ? blob.giftcard.amt : ''}}
      </v-btn>
    </div>

    <div
      v-if="[ 'purchase', 'edit' ].includes(mode) && !blob.giftcard.claim_id && showValue && !isVerifiedRecipient"
      class="d-flex justify-center"
      style="position: absolute; width: 100%; pointer-events: auto; bottom: -20px;"
      v-bind:style="{ transform: `rotate(${-blob.r}deg)` }"
    >
      <v-btn
        v-blur
        rounded elevation="6" color="primary"
        @click="$emit('click')"
      >
        {{(mode == 'purchase') || (blob.sample && isOwner) ? 'Edit amount…' : 'Add to gift card…'}}
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
.clickable {
  cursor: pointer;
  pointer-events: auto;
}
.clickable:hover {
  background-color: rgba(0,0,0,.2);
}
</style>

<script>
import Currency from '@/components/Currency.vue';

export default {
  name: 'giftcard-blob-controls',
  components: { Currency },
  props: [ 'blob', 'skin', 'isOwner', 'isCardOwner', 'isVerifiedRecipient', 'scale', 'mode' ],
  computed: {
    showValue() {
      return this.isOwner || this.isCardOwner || this.isVerifiedRecipient || !this.skin?.hide_giftcard_totals;
    }
  }
}
</script>
