var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', _vm._g(_vm._b({
    key: _vm.msg,
    attrs: {
      "eager": "",
      "max-width": "300"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (on) {
        return [_vm._t("default", null, {
          "on": {
            touchstart: _vm.touchstart,
            mouseenter: _vm.mouseenter,
            mouseleave: _vm.mouseleave
          },
          "bind": {
            disabled: _vm.disabled
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, 'v-tooltip', _vm.$attrs, false), _vm.$listeners), [_c('span', [_vm._v(_vm._s(_vm.msg))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }