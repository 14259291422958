<template>
  <div>
    <div
      class="resize-box absolute-fill"
      v-bind:class="{ 'as-override': !isPrimary && !isCropping, 'as-crop': isCropping }"
    />
    <template v-if="canTransform">
      <div
        v-if="blob.type != 'BTN'"
        v-for="[x, y] in [ [-1, -1], [1, -1], [1, 1], [-1, 1] ]" :key="`${x}${y}`"
        class="corner-btn"
        v-bind:class="{ 'as-crop': isCropping }"
        v-bind:style="{
          left: x < 0 ? - btnOffset + 'px' : undefined,
          right: x > 0 ? - btnOffset + 'px' : undefined,
          top: y < 0 ? - btnOffset + 'px' : undefined,
          bottom: y > 0 ? - btnOffset + 'px' : undefined
        }"
        @mousedown="e => $emit('resize', e)"
        @touchstart="e => $emit('resize', e)"
      >
        <!-- We want the buttons to look small, but still be usable on mobile, so we use the containing div as an invisible background "button" -->
        <v-btn class="ma-3" v-blur fab x-small absolute color="red" :disabled="isShowingVirtualKeyboard" />
      </div>
      <template v-if="isCropping">
        <div class="absolute-fill d-flex align-end" style="bottom: 0;" @click.capture.stop.prevent>
          <v-slider
            class="crop-rotate-slider"
            style="pointer-events: auto;"
            min="-45" max="45" hide-details color="transparent" track-color="transparent" thumb-label="always" thumb-color="amber accent-4"
            :thumb-size="44"
            :step="snap ? 5 : 1"
            :value="blob.crop.r > 45 ? blob.crop.r - 360 : blob.crop.r"
            @input="v => blob.crop.r = v < 0 ? 360 + v : v"
          >
            <template v-slot:thumb-label>
              <v-icon color="white" v-html="mdiCropRotate" />
            </template>
          </v-slider>
        </div>
      </template>
      <template v-else>
        <v-btn
          v-if="!blob.btn?.form && (![ 'GIFTCARD', 'GROUP_VIDEO' ].includes(blob.type) || mode == 'purchase' || blob.sample)"
          v-blur
          style="left: calc(50% - 28px); top: -96px; pointer-events: auto;"
          fab absolute color="red"
          @click.stop="$emit('delete')"
        >
          <v-icon color="white" v-html="mdiDelete" />
          <span class="btn-tooltip bottom">Delete</span>
        </v-btn>
        <v-btn
          v-blur
          style="left: calc(50% - 28px); bottom: -96px; pointer-events: auto;"
          fab absolute color="red"
          :disabled="isShowingVirtualKeyboard"
          @mousedown="e => $emit('rotate', e)"
          @touchstart="e => $emit('rotate', e)"
        >
          <v-icon color="white" v-html="mdiRotateRight" />
          <span class="btn-tooltip top">Drag to rotate</span>
        </v-btn>
      </template>
    </template>
  </div>
</template>


<style scoped>
.resize-box {
  outline: 4px dashed red;
  box-shadow:
    inset 0 0 8px 8px rgba(255, 255, 255, .2),
    0 0 8px 8px rgba(255, 255, 255, .2);
  transition: box-shadow .3s;
}
.resize-box.as-override {
  /* This color matches the Vuetify 'blue' (which is probably available as an scss variable somewhere...) */
  outline: 4px dashed rgb(33,150,243);
}
.resize-box.as-crop {
  /* You could use this to align the outline tightly to the bounds of an IMAGE of VIDEO blob */
  /* outline-offset: -8px; */
  outline: 4px dashed #FFAB00;
  box-shadow:
    inset 0 0 8px 8px rgba(0, 0, 0, .2),
    0 0 8px 8px rgba(0, 0, 0, .2),
    0 0 1280px 1280px rgba(0, 0, 0, .2);
}
.corner-btn {
  position: absolute;
  width: 56px;
  height: 56px;
  pointer-events: auto;
}
.corner-btn.as-crop > button {
  background-color: #FFAB00 !important;
}
</style>

<style>
.crop-rotate-slider .v-slider__thumb-label-container {
  transform: rotate(180deg);
  transform-origin: center 4px;
}

.crop-rotate-slider .v-slider__thumb {
  opacity: 0;
}

.crop-rotate-slider .v-slider__thumb-label {
  cursor: pointer;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
</style>


<script>
import { mdiRotateRight, mdiDelete, mdiCropRotate } from '@mdi/js';


export default {
  name: 'blob-selection-controls',

  props: [ 'blob', 'canTransform', 'isPrimary', 'mode', 'isShowingVirtualKeyboard', 'isCropping', 'snap' ],

  created() {
    Object.assign(this, { btnOffset: 30, mdiRotateRight, mdiDelete, mdiCropRotate });
  }
}
</script>
