var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "absolute-fill back gc-border",
    class: {
      clickable: _vm.mode == 'view'
    }
  }, _vm.mode == 'view' ? {
    click: function () {
      return _vm.$emit('click');
    }
  } : {}), [_c('div', {
    staticClass: "absolute"
  }, [_c('v-btn', _vm._g({
    directives: [{
      name: "blur",
      rawName: "v-blur"
    }],
    staticClass: "mt-n5 ml-n5",
    staticStyle: {
      "transition": "unset"
    },
    style: {
      'cursor': _vm.mode == 'preview' ? null : 'pointer',
      'pointer-events': _vm.mode == 'preview' ? null : 'auto',
      'transform': `rotate(${-_vm.blob.r}deg)`
    },
    attrs: {
      "rounded": "",
      "dark": "",
      "color": _vm.blob.giftcard.claim_id ? 'grey lighten-1' : 'green lighten-2'
    }
  }, _vm.mode == 'preview' || !_vm.showValue ? {} : {
    click: function () {
      return _vm.$emit('click');
    }
  }), [_c('currency', {
    attrs: {
      "code": _vm.blob.giftcard.cur
    }
  }), _vm._v(_vm._s(_vm.showValue ? _vm.blob.giftcard.amt : '') + " ")], 1)], 1), ['purchase', 'edit'].includes(_vm.mode) && !_vm.blob.giftcard.claim_id && _vm.showValue && !_vm.isVerifiedRecipient ? _c('div', {
    staticClass: "d-flex justify-center",
    staticStyle: {
      "position": "absolute",
      "width": "100%",
      "pointer-events": "auto",
      "bottom": "-20px"
    },
    style: {
      transform: `rotate(${-_vm.blob.r}deg)`
    }
  }, [_c('v-btn', {
    directives: [{
      name: "blur",
      rawName: "v-blur"
    }],
    attrs: {
      "rounded": "",
      "elevation": "6",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.mode == 'purchase' || _vm.blob.sample && _vm.isOwner ? 'Edit amount…' : 'Add to gift card…') + " ")])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }