import uuid4 from '@/utils/uuid.js';
import opDevCall from '@/mixins/op-dev-call.js';


const CACHE_TTL_MS = 3600000;

const blankDesignId = '3a824a27-1595-4b8e-bb28-4ad9b3a8a030';
const blankDesign = {
  name: 'Design your own card',
  status: 'UP',
  background: { img: null, color: '#FFF' },
  pages: [ { id: uuid4(), blobs: [] } ]
};

const nullCoverDesignId = '2889addf-684e-4c17-b8d0-475016adf7e0';
const nullCoverDesign = {
  name: 'Blank',
  status: 'UP',
  background: { img: null, color: '#FFF' },
  pages: [ { id: uuid4(), blobs: [] } ]
};

const DESIGN_CACHE = {
  [blankDesignId]: { task: Promise.resolve(blankDesign) },
  [nullCoverDesignId]: { task: Promise.resolve(nullCoverDesign) }
};

const publicDesign = {

  mixins: [ opDevCall ],

  created() {
    Object.assign(this, { blankDesignId, nullCoverDesignId });
  },

  methods: {
    async getPublicDesign(design_id) {
      if (!DESIGN_CACHE[design_id] || (new Date() - DESIGN_CACHE[design_id].t > CACHE_TTL_MS))
        DESIGN_CACHE[design_id] = {
          t: new Date(),
          task: this.$call('get-public-design', { design_id })
        };
      const design = await DESIGN_CACHE[design_id].task;
      if ((design !== blankDesign) && (design !== nullCoverDesign) && !(design && [ 'UP', 'SUBMITTED' ].includes(design.status) && !design.private && design.r))
        delete DESIGN_CACHE[design_id];
      return design;
    }
  }
};

export { blankDesignId, blankDesign, nullCoverDesignId, nullCoverDesign, publicDesign };
