<template>
  <nav-menu name="Start" v-bind="$attrs" v-on="$listeners">
    <start-btn-list class="transparent" />
  </nav-menu>
</template>


<script>
import NavMenu from '@/components/NavMenu.vue';
import StartBtnList from '@/components/StartBtnList.vue';

export default {
  name: 'nav-menu-start',
  components: { NavMenu, StartBtnList }
}
</script>
