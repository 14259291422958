import { LOCALE, TZ } from '@/utils/intl.js';
import store from '@/utils/local-storage.js';
import uuid4 from '@/utils/uuid.js';

const ROOT = process.env.OP_DEV_CONFIG['service-url'].replace(/\/$/, '');

class EventTracker {

  constructor(vm) {
    this.vm = vm;
    this.queue = [];
    this.last = null;
    this.pending = null;
    this.instanceId = uuid4();

    if (store.isAvailable()) {
      this.browserId = store.get('browserId');
      if (!this.browserId) {
        this.browserId = uuid4();
        store.set('browserId', this.browserId);
      }
    } else {
      // No access to local storage
      this.browserId = null;
    }

    // The 'visibilitychange' does not fire when tabbing into another application (tested on Chrome desktop)
    addEventListener('visibilitychange', () => this._onFocus(document.visibilityState == 'visible'));
    addEventListener('focus', () => this._onFocus(true));
    addEventListener('blur', () => this._onFocus(false));
    addEventListener('beforeunload', this.flush);

    this._scheduleFlush();
  }

  _onFocus(active) {
    // If the last event we recorded matches this one then they must be duplicates and we can ignore it
    if ((this.last?.type == 'focus') && (this.last.e == active))
      return;
    this.log('focus', active, !active);
  }

  async _scheduleFlush() {
    while (true) {
      await new Promise(r => setTimeout(r, 60000));
      try {
        await this.flush();
      } catch (e) {
        // Warning: this could cause events to build up, eventually exceeding the allowed size we can pass to the
        // beacon request
        console.warn('Failed to flush events');
      }
    }
  }

  async log(type, e, flush=false) {
    if (this.pending)
      await this.pending;
    const user_id = this.vm.globalUserId || null;
    this.queue.push(this.last = { type, at: (new Date()).getTime(), user_id, e });

    if (flush)
      this.flush();
  }

  async flush() {
    while (this.pending)
      await this.pending;

    if (!this.queue?.length)
      return;

    if (this.vm.global.profile?.disable_tracking) {
      this.queue = [];
      return;
    }

    this.pending = new Promise(r => {
      try {
        const payload = [
          (new Date()).getTime(),
          this.browserId || '',
          this.instanceId || '',
          LOCALE,
          TZ,
          JSON.stringify(this.queue)
        ].join(',');
        if (navigator.sendBeacon(ROOT + '/capture-events', payload))
          this.queue = [];
      } finally {
        r();
      }
    });
    await this.pending;
    this.pending = null;
  }

}

export default EventTracker;
