// We can avoid the additional call to 'fonts.googleapis.com' for these font families
const KNOWN_FONTS = {
  'Amatic SC': [ [ 400, 'https://fonts.gstatic.com/s/amaticsc/v24/TUZyzwprpvBS1izr_vOECuSfU5cP1Q.woff2' ] ],
  'Architects Daughter': [ [ 400, 'https://fonts.gstatic.com/s/architectsdaughter/v18/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvTYlg4-7jA-U.woff2' ] ], 
  'Average': [ [ 400, 'https://fonts.gstatic.com/s/average/v16/fC1hPYBHe23MxA7rEeV6VE6uXyg.woff2' ] ],
  'Balsamiq Sans': [ [ 400, 'https://fonts.gstatic.com/s/balsamiqsans/v10/P5sEzZiAbNrN8SB3lQQX7Pncwd4XIKVODzo.woff2' ] ],
  'Berkshire Swash': [ [ 400, 'https://fonts.gstatic.com/s/berkshireswash/v16/ptRRTi-cavZOGqCvnNJDl5m5XmN_qs4zT305QQ.woff2' ] ],
  'Bubblegum Sans': [ [ 400, 'https://fonts.gstatic.com/s/bubblegumsans/v16/AYCSpXb_Z9EORv1M5QTjEzMEteaAxILtbKR5.woff2' ] ],
  'Caveat': [ [ 400, 'https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9eIWpYT5Kmgq3sw.woff2' ] ],
  'Chelsea Market': [ [ 400, 'https://fonts.gstatic.com/s/chelseamarket/v13/BCawqZsHqfr89WNP_IApC8tzKChiJg8MKVWl.woff2' ] ],
  'Chewy': [ [ 400, 'https://fonts.gstatic.com/s/chewy/v18/uK_94ruUb-k-wn52KjI9OPec.woff2' ] ],
  'Dancing Script': [ [ 400, 'https://fonts.gstatic.com/s/dancingscript/v23/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup6hNX6plRP.woff' ] ],
  'Emilys Candy': [ [ 400, 'https://fonts.gstatic.com/s/emilyscandy/v13/2EbgL-1mD1Rnb0OGKudbk0yJqNZqfc0ZjQ.woff2' ] ],
  'Fondamento': [ [ 400, 'https://fonts.gstatic.com/s/fondamento/v16/4UaHrEJGsxNmFTPDnkaJ96rp57F2IwM.woff2' ] ],
  'Fredericka the Great': [ [ 400, 'https://fonts.gstatic.com/s/frederickathegreat/v15/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV--SjxbcHcQ2K.woff2' ] ],
  'Grand Hotel': [ [ 400, 'https://fonts.gstatic.com/s/grandhotel/v13/7Au7p_IgjDKdCRWuR1azplQKGFws0VEw.woff2' ] ],
  'Homemade Apple': [ [ 400, 'https://fonts.gstatic.com/s/homemadeapple/v18/Qw3EZQFXECDrI2q789EKQZJob0x6XHgOiJM6.woff2' ] ],
  'Indie Flower': [ [ 400, 'https://fonts.gstatic.com/s/indieflower/v17/m8JVjfNVeKWVnh3QMuKkFcZVaUuH99GUDg.woff2' ] ],
  'Kaushan Script': [ [ 400, 'https://fonts.gstatic.com/s/kaushanscript/v14/vm8vdRfvXFLG3OLnsO15WYS5DG74wNJVMJ8b.woff2' ] ],
  'Lobster': [ [ 400, 'https://fonts.gstatic.com/s/lobster/v28/neILzCirqoswsqX9zoKmM4MwWJU.woff2' ] ],
  'Luckiest Guy': [ [ 400, 'https://fonts.gstatic.com/s/luckiestguy/v18/_gP_1RrxsjcxVyin9l9n_j2hTd52ijl7aQ.woff2' ] ],
  // 'Nunito:wght@400;900': [ [ 400, 'https://fonts.gstatic.com/s/nunito/v26/XRXV3I6Li01BKofINeaBTMnFcQ.woff2' ], [ 900, 'https://fonts.gstatic.com/s/nunito/v26/XRXV3I6Li01BKofINeaBTMnFcQ.woff2' ] ],
  // 'Livvic:wght@900': [ [ 900, 'https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlff68QutV3DY_GtWA.woff2' ] ],
  'Permanent Marker': [ [ 400, 'https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004La2Cf5b6jlg.woff2' ] ],
  'Ranchers': [ [ 400, 'https://fonts.gstatic.com/s/ranchers/v13/zrfm0H3Lx-P2Xvs2ArDfBi_fXiHu.woff2' ] ],
  'Rock Salt': [ [ 400, 'https://fonts.gstatic.com/s/rocksalt/v18/MwQ0bhv11fWD6QsAVOZrt0M6p7NGrQ.woff2' ] ],
  'Sacramento': [ [ 400, 'https://fonts.gstatic.com/s/sacramento/v13/buEzpo6gcdjy0EiZMBUG4C0f_f5Iai0.woff2' ] ]
};


const BLOB_URL_CACHE = {};
async function resolve([ weight, url ]) {
  // This is specifically for resolving web fonts from remote URLs and encoding them as data URLs to be used in-line
  if (url.startsWith('data'))
    return url;
  if (!BLOB_URL_CACHE[url]) {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    const e = await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
    BLOB_URL_CACHE[url] = [ weight, e.target.result ];
  }
  return BLOB_URL_CACHE[url];
}


async function loadFontKernel(family, key) {
  // if (document.fonts.check('12px ' + family))
  //   return;
  let urls = KNOWN_FONTS[key];
  if (!urls) {
    // The requested 'key' is not in our 'KNOWN_FONTS', assume it is a Google font family and extract the relevant URLs
    // from the returned stylesheet
    const response = await fetch(`https://fonts.googleapis.com/css2?family=${key}`);
    const css = await response.text();
    const re = new RegExp(/\/\* latin \*\/\n@font-face {[^\}]+font-weight: (\d+)[^\}]+src: url\(([^\)]+)\)[^\}]+}/, 'g');
    urls = [];
    let match;
    while (match = re.exec(css))
      urls.push([ match[1], match[2] ]);
  }
  // Note that we don't actually have to resolve the URL here to load the font properly, but we do need to explicitly
  // return a resolved data URL to help with some of the functions in render-text.js
  const blobURLs = await Promise.all(urls.map(resolve));
  for (const [ weight, url ] of blobURLs) {
    const font = new FontFace(family, `url(${url})`, { weight });
    await font.load();
    document.fonts.add(font);
  }
  return blobURLs;
}

function buildKey(family, weights) {
  return family + (weights ? (':wght@' + weights.join(';')) : '');
}

const CACHE = {};
async function loadFont(family, weights) {
  const key = buildKey(encodeURIComponent(family), weights);
  return CACHE[key] ||= loadFontKernel(family, key);
}


export default loadFont;
