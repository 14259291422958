import { cache, ssr } from '@/mixins/ssr.js';
import { COUNTRY_CODE } from '@/utils/intl.js';
import { DEFAULT } from '@/utils/skin.js';
import { format } from '@/utils/time.js';

const CACHE = {};
const SORT_TOP = [ 'greeting-cards', 'invitations' ];

export default {
  mixins: [ ssr ],

  data() {
    return {
      loadCategoriesStatus: this.$track('loadCategories')
    };
  },

  computed: {
    loadCategoriesKey() {
      const date = format(new Date(), 'iso-date');
      const country = COUNTRY_CODE;
      return [ date, country, this.global.profile?.org_id ];
    },

    hydrated() {
      return !this.loadCategoriesStatus.pending;
    }
  },

  methods: {
    async loadCategories(withPreviews) {
      const [ date, country ] = this.loadCategoriesKey;
      const key = JSON.stringify(this.loadCategoriesKey);
      if (CACHE.promise && (CACHE.key == key) && !withPreviews || CACHE.withPreviews)
        return await CACHE.promise;
      const promise = new Promise(
        async (resolve, reject) => {
          try {
            const compressed = await cache(this.$call)('list-categories', { date, country, all: withPreviews });
            const expanded = compressed.map(
              x => {
                const skin = x[8] || {};
                const output = {
                  seasonality: x[0],
                  type: x[1],
                  name: x[2],
                  title: x[3],
                  title_color: x[4],
                  icon: x[5],
                  icon_color: x[6],
                  description: x[7],
                  skin: Object.assign({}, DEFAULT, skin),
                  preview: x[9],
                  _sort_key: ((1 + SORT_TOP.indexOf(x[2])) || 'z') + x[0] + x[3]
                };
                if (output.name == 'greeting-cards') {
                  output.seasonality = -1;
                  output.title = 'All greeting cards';
                  output.design_your_own = 'card';
                  output.description = '';
                } else if (output.name == 'invitations') {
                  output.seasonality = -1;
                  output.title = 'All invitations';
                  output.design_your_own = 'invitation';
                  output.description = '';
                }
                return output;
              }
            );
            expanded.sort((a, b) => a._sort_key.localeCompare(b._sort_key));
            resolve(expanded);
          } catch (e) {
            reject(e);
          }
        }
      );
      Object.assign(CACHE, { key, withPreviews, promise });
      return await promise;
    }
  }
};
