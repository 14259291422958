var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "absolute-fill",
    style: {
      background: `linear-gradient(${_vm.blob.gradient.r}deg, ${_vm.blob.gradient.start}, ${_vm.blob.gradient.stop})`
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }