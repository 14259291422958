<template>
  <div>

    <error-dialog :status="sendCodeStatus" />
    <error-dialog :status="verifyCodeStatus" />

    <captcha-overlay v-if="showCaptcha" @cancel="showCaptcha = false" @complete="sendCode" />

    <v-slide-x-transition v-if="!sendCodeStatus.resolved" leave-absolute>
      <v-text-field
        class="mt-0"
        v-model="email"
        name="Email address"
        label="Email address"
        hint="We'll send you an email with a temporary code"
        persistent-hint
        :error="(!!email) && !isValid(email)"
        @keydown.stop
        @keydown.enter="showCaptcha = !!isValid(email)"
      >
        <template v-slot:append>
          <v-btn
            style="margin-bottom: 5px;" small color="primary"
            :loading="sendCodeStatus.pending"
            :disabled="!isValid(email)"
            @click="showCaptcha = true"
          >
            Send
          </v-btn>
        </template>
      </v-text-field>
    </v-slide-x-transition>
    <v-slide-x-reverse-transition v-else leave-absolute>
      <code-input
        ref="code-input"
        class="mb-1 mt-2"
        :busy="verifyCodeStatus.pending"
        :invalid="codeHasError"
        :exp="exp"
        type="email"
        @reset="sendCodeStatus.reset()"
        @reset-code="codeHasError = false"
        @verify="verifyCode"
      />
    </v-slide-x-reverse-transition>
  </div>
</template>


<script>
import CaptchaOverlay from '@/components/CaptchaOverlay.vue';
import CodeInput from '@/components/CodeInput.vue';
import ErrorDialog from '@/components/ErrorDialog.vue';
import { EMAIL_REGEX } from '@/utils/email.js';
import { OPError } from '@/utils/op-dev-fetch';


export default {
  name: 'sign-in-email',

  components: {
    CaptchaOverlay,
    CodeInput,
    ErrorDialog
  },

  props: [ 'subscribe', 'brandedOrgId' ],

  data() {
    return {
      email: null,
      sendCodeStatus: this.$track('sendCode'),
      verifyCodeStatus: this.$track('verifyCode'),
      codeHasError: false,
      showCaptcha: false
    };
  },

  methods: {
    isValid(email) {
      return email && EMAIL_REGEX.test(email);
    },

    async sendCode(captcha) {
      this.showCaptcha = false;
      this.codeHasError = false;
      try {
        this.exp = await this.$call('send-sign-in-code', { email: this.email, path: this.$route.fullPath, captcha, org_id: this.brandedOrgId });
      } catch (e) {
        if (e instanceof OPError && e.type == 'ClientException')
          throw new VisibleError(e.error || 'Error sending sign in code');
        throw e;
      }
    },

    async verifyCode(code) {
      try {
        const params = { email: this.email, method: 'CODE', payload: code, subscribe: this.subscribe };
        const jwt = await this.$call('get-jwt', params);
        this.$emit('sign-in', jwt);
      } catch (e) {
        if (e.type == 'AuthenticationException') {
          this.codeHasError = true;
          return;
        }
        throw e;
      }
    },

    reset() {
      this.$refs['code-input'] && this.$refs['code-input'].reset();
    }
  }
}
</script>
