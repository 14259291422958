var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._g(_vm._b({
    directives: [{
      name: "no-scroll",
      rawName: "v-no-scroll"
    }],
    attrs: {
      "width": "500",
      "persistent": _vm.opts.persistent || _vm.persistent,
      "value": _vm.value
    },
    on: {
      "input": _vm.onInput
    }
  }, 'v-dialog', _vm.$attrs, false), _vm.$listeners), [_vm._t("alert"), _c('v-card', [!_vm.persistent ? _c('v-btn', {
    staticClass: "float-right mt-3 mr-3",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.onInput(false);
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiClose)
    }
  })], 1) : _vm._e(), _vm.isSignedIn ? [_c('v-card-title', [_vm._v("Signed in")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-5"
  }, [_vm._v("Email address: " + _vm._s(_vm._f("email")(_vm.global.profile.email)))]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "loading": _vm.signOutStatus.pending
    },
    on: {
      "click": _vm.signOut
    }
  }, [_vm._v("Sign out")])], 1)] : [_c('v-card-title', [_vm._t("title", function () {
    return [_vm.opts.title ? [_vm._v(_vm._s(_vm.opts.title))] : [_vm._v("Sign in / create account")]];
  }), _c('v-spacer')], 2), _c('v-card-subtitle', {
    staticClass: "ma-0"
  }, [_vm._t("subtitle", function () {
    return [_vm.opts.subtitle ? [_vm._v(_vm._s(_vm.opts.subtitle))] : [_vm._v("Save your work and manage your purchases")]];
  })], 2), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('sign-in-email', {
    ref: "sign-in-email",
    attrs: {
      "branded-org-id": _vm.brandedOrgId,
      "subscribe": _vm.subscribe
    },
    on: {
      "sign-in": function (jwt) {
        return _vm.signIn(jwt, 'Email code');
      }
    }
  }), _c('sign-in-btn-google', {
    staticClass: "mt-6",
    attrs: {
      "subscribe": _vm.subscribe
    },
    on: {
      "sign-in": function (jwt) {
        return _vm.signIn(jwt, 'Google');
      }
    }
  }), _c('sign-in-btn-facebook', {
    staticClass: "mt-6",
    attrs: {
      "subscribe": _vm.subscribe
    },
    on: {
      "sign-in": function (jwt) {
        return _vm.signIn(jwt, 'Facebook');
      }
    }
  }), _vm.allowAnonymous ? _c('sign-in-btn-anonymous', {
    staticClass: "mt-6",
    on: {
      "click": function ($event) {
        return _vm.onInput(false);
      }
    }
  }) : _vm._e(), !_vm.IS_IFRAME && !_vm.hideSubscribe ? _c('v-checkbox', {
    staticClass: "mt-6",
    attrs: {
      "label": "Join our email list",
      "hide-details": ""
    },
    model: {
      value: _vm.isSusbcribeChecked,
      callback: function ($$v) {
        _vm.isSusbcribeChecked = $$v;
      },
      expression: "isSusbcribeChecked"
    }
  }) : _vm._e()], 1), [_c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4 text-caption"
  }, [_vm._v(" We do "), _c('b', [_vm._v("not")]), _vm._v(" use any personal details from third-party services. We only offer them as a convenience to verify your email address"), !_vm.IS_IFRAME ? _c('span', [_vm._v(" — "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "/privacy-policy"
    }
  }, [_vm._v("privacy policy")])]) : _vm._e(), _vm._v(". ")])]]], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }