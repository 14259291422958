var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.selected || _vm.selected === _vm.blob ? _c('div', {
    staticClass: "absolute-fill d-flex justify-center align-center",
    class: {
      'align-end': !!_vm.blob.group_video.img_src
    }
  }, [_c('div', {
    staticStyle: {
      "pointer-events": "auto"
    },
    attrs: {
      "Xv-bind:style": "{ transform: `scale(${1 / scale}) rotate(${-blob.r}deg)` }"
    }
  }, [_vm.blob.group_video.img_src ? _c('v-btn', {
    directives: [{
      name: "blur",
      rawName: "v-blur"
    }],
    attrs: {
      "rounded": "",
      "dark": "",
      "elevation": "6",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v(" " + _vm._s(['purchase', 'edit'].includes(_vm.mode) ? 'Add to' : 'Play') + " video ")]) : _c('v-btn', {
    directives: [{
      name: "blur",
      rawName: "v-blur"
    }],
    attrs: {
      "fab": "",
      "x-large": "",
      "dark": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    },
    domProps: {
      "innerHTML": _vm._s(['purchase', 'edit'].includes(_vm.mode) ? _vm.mdiPencil : _vm.mdiPlay)
    }
  })], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }