var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "width": "100%",
      "max-width": "320px"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiRefreshCircle))])], 1), _c('v-spacer'), _vm._l(4, function (i) {
    return [i != 1 ? _c('v-spacer') : _vm._e(), _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: '#',
        expression: "'#'"
      }],
      ref: "codeDigit",
      refInFor: true,
      staticClass: "code-digit-input flex-grow-0",
      attrs: {
        "dense": "",
        "outlined": "",
        "type": "tel",
        "name": 'Code digit ' + i,
        "autocomplete": "-",
        "autofocus": i == 1,
        "value": _vm.code[i - 1]
      },
      on: {
        "input": function (v) {
          _vm.$set(_vm.code, i - 1, v);
          _vm.tryAdvanceFocus(i);
        },
        "keydown": [function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          i == 4 && _vm.$emit('verify', _vm.joinedCode);
        }, function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) return null;
          $event.preventDefault();
          return _vm.deleteDigit(i);
        }],
        "click": function ($event) {
          return _vm.select(i);
        },
        "paste": function ($event) {
          $event.stopPropagation();
          $event.preventDefault();
          return function (e) {
            return _vm.onPaste(e, i);
          }.apply(null, arguments);
        }
      }
    })];
  }), _c('v-spacer'), _vm.invalid ? _c('v-btn', {
    attrs: {
      "icon": "",
      "large": "",
      "color": "error"
    },
    on: {
      "click": function ($event) {
        return _vm.resetCode();
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiClose))])], 1) : _c('v-btn', {
    attrs: {
      "icon": "",
      "large": "",
      "color": "primary",
      "loading": _vm.busy,
      "disabled": _vm.code.find(function (i) {
        return !i;
      })
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('verify', _vm.joinedCode);
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiCheckCircle))])], 1)], 2)]), _c('div', {
    staticClass: "v-messages mt-n5"
  }, [_c('div', {
    staticClass: "v-messages__wrapper"
  }, [_c('div', {
    staticClass: "v-messages__message d-flex justify-center"
  }, [_vm.invalid ? _c('div', {
    staticClass: "error--text"
  }, [_vm._v("Invalid code")]) : _c('div', [_c('div', [_vm._v("Check your " + _vm._s(_vm.type) + " and enter the code here")]), _vm.type == 'email' ? _c('div', {
    staticClass: "mt-1"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'faq',
        hash: '#never-received-sign-in-email'
      },
      "target": "_blank"
    }
  }, [_vm._v(" Didn't receive an email? ")])], 1) : _vm._e()]), _c('v-progress-circular', {
    staticClass: "ml-1",
    attrs: {
      "size": "12",
      "width": "2",
      "rotate": "-90",
      "value": _vm.expPct
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }