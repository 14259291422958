// TODO: add date formats in here and use with the batch-order tool

// https://github.com/cjustinobi/vue-currency-symbol/blob/master/src/countries.json
const CURRENCY_CODES = {
  'AU': 'AUD',
  'CA': 'CAD',
  'CH': 'CHF',
  'CZ': 'CZK',
  'DK': 'DKK',
  'AT': 'EUR',
  'BE': 'EUR',
  'FI': 'EUR',
  'FR': 'EUR',
  'DE': 'EUR',
  'EE': 'EUR',
  'GR': 'EUR',
  'HR': 'EUR',
  'IE': 'EUR',
  'IT': 'EUR',
  'LU': 'EUR',
  'LT': 'EUR',
  'LV': 'EUR',
  'NL': 'EUR',
  'PL': 'EUR',
  'PT': 'EUR',
  'SI': 'EUR',
  'SK': 'EUR',
  'ES': 'EUR',
  'GB': 'GBP',
  'HU': 'HUF',
  'IN': 'INR',
  'JP': 'JPY',
  'MX': 'MXN',
  'NO': 'NOK',
  'NZ': 'NZD',
  'RO': 'RON',
  'SE': 'SEK',
  'SG': 'SGD',
  'US': 'USD'
};

const CURRENCY_SYMBOLS = {
  'AUD': '&#36;',
  'EUR': '&#8364;',
  'CAD': '&#36;',
  'CHF': '&#8355;',
  'CZK': '&#75;&#269;',
  'DKK': '&#107;&#114;',
  'HUF': '&#70;&#116;',
  'INR': '&#8377;',
  'JPY': '&#165;',
  'MXN': '&#36;',
  'NOK': '&#107;&#114;',
  'NZD': '&#36;',
  'RON': '&#108;&#101;&#105;',
  'SEK': '&#107;&#114;',
  'SGD': '&#36;',
  'GBP': '&#163;',
  'USD': '&#36;'
};

const AMBIGUOUS_CURRENCY_SYMBOLS = new Set([ '&#36;' ]);

// Note: you could use a filter like this instead of maintaining your own map of currency symbols, but we'll save that
// for a bigger ovehaul in the future
// export default (v, currency) => {
//   const f = new Intl.NumberFormat('en-US', { style: 'currency', currency });
//   return v ? f.format(v) : f.formatToParts(0).find(x => x.type == 'currency').value;
// };


// Get the user's locale (which includes language and country code) and time zone
// Note that the returned 'locale' may not be formatted as expected, see this list:
// https://stackoverflow.com/a/3191729
function getCountryCode(locale) {
  try {
    return locale.match(/^.*([A-Z]{2})$/)[1];
  } catch (e) {
    console.warn('Failed to resolve country code');
    return 'US';
  }
}

function getLocale() {
  try {
    const opts = Intl.DateTimeFormat().resolvedOptions();
    return { LOCALE: opts.locale, TZ: opts.timeZone, COUNTRY_CODE: getCountryCode(opts.locale) };
  } catch (e) {
    console.warn('Failed to resolve locale');
    return { LOCALE: 'en-US', TZ: 'America/New_York', COUNTRY_CODE: 'US' };
  }
}

const { LOCALE, COUNTRY_CODE, TZ } = getLocale();

export { AMBIGUOUS_CURRENCY_SYMBOLS, CURRENCY_CODES, CURRENCY_SYMBOLS, LOCALE, COUNTRY_CODE, TZ };
