<template>
  <v-dialog
    v-no-scroll
    width="500"
    :persistent="opts.persistent || persistent"
    :value="value"
    @input="onInput"
    v-bind="$attrs" v-on="$listeners"
  >

    <slot name="alert">
    </slot>

    <v-card>
      <v-btn v-if="!persistent" icon class="float-right mt-3 mr-3" @click="onInput(false)">
        <v-icon v-html="mdiClose" />
      </v-btn>
      <template v-if="isSignedIn">
        <v-card-title>Signed in</v-card-title>
        <v-divider />
        <v-card-text class="pt-5">Email address: {{global.profile.email | email}}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn :loading="signOutStatus.pending" @click="signOut">Sign out</v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-title>
          <slot name="title">
            <template v-if="opts.title">{{opts.title}}</template>
            <template v-else>Sign in / create account</template>
          </slot>
          <v-spacer />
        </v-card-title>
        <v-card-subtitle class="ma-0">
          <slot name="subtitle">
            <template v-if="opts.subtitle">{{opts.subtitle}}</template>
            <template v-else>Save your work and manage your purchases</template>
          </slot>
        </v-card-subtitle>

        <v-divider />
        <v-card-text class="pt-4">
          <sign-in-email
            ref="sign-in-email"
            :branded-org-id="brandedOrgId"
            :subscribe="subscribe"
            @sign-in="jwt => signIn(jwt, 'Email code')"
          />
          <sign-in-btn-google class="mt-6" :subscribe="subscribe" @sign-in="jwt => signIn(jwt, 'Google')" />
          <sign-in-btn-facebook class="mt-6" :subscribe="subscribe" @sign-in="jwt => signIn(jwt, 'Facebook')" />
          <sign-in-btn-anonymous v-if="allowAnonymous" class="mt-6" @click="onInput(false)" />
          <v-checkbox
            v-if="!IS_IFRAME && !hideSubscribe"
            class="mt-6"
            v-model="isSusbcribeChecked"
            label="Join our email list"
            hide-details
          />
        </v-card-text>
        <template>
          <v-divider />
          <v-card-text class="mt-4 text-caption">
            We do <b>not</b> use any personal details from third-party services. We only offer them as a convenience to verify your email address<span v-if="!IS_IFRAME"> &mdash; <a target="_blank" href="/privacy-policy">privacy policy</a></span>.
          </v-card-text>
        </template>
      </template>
    </v-card>
  </v-dialog>
</template>


<style type="text/css">
@font-face{
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/s/googlesans/v14/4UabrENHsxJlGDuGo1OIlLU94YtzCwM.ttf) format('truetype');
}

.btn-like-google-sign-in {
  height: 40px !important;
  border: 1px solid #dadce0;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 14px;
  color: rgb(60, 64, 67) !important;
  font-family: 'Google Sans', arial, sans-serif !important;
}

.btn-like-google-sign-in .v-icon {
  margin-left: -8px;
  margin-right: 4px;
}
</style>


<script>
import SignInBtnAnonymous from '@/components/SignInBtnAnonymous.vue';
import SignInBtnFacebook from '@/components/SignInBtnFacebook.vue';
import SignInBtnGoogle from '@/components/SignInBtnGoogle.vue';
import SignInEmail from '@/components/SignInEmail.vue';
import store from '@/utils/local-storage.js';
import { mdiAlert, mdiClose } from '@mdi/js';


export default {
  name: 'sign-in-dialog',

  components: {
    SignInBtnAnonymous,
    SignInBtnFacebook,
    SignInBtnGoogle,
    SignInEmail
  },

  props: {
    value: Boolean,
    persistent: Boolean,
    allowAnonymous: Boolean,
    brandedOrgId: String,
    hideSubscribe: Boolean
  },

  data() {
    return {
      opts: {},
      isSusbcribeChecked: true,
      resolvePrompt: null,
      signOutStatus: this.$track('signOut')
    };
  },

  computed: {
    subscribe() {
      return this.isSusbcribeChecked && !this.IS_IFRAME && !this.brandedOrgId && !this.hideSubscribe;
    }
  },

  created() {
    Object.assign(this, { mdiAlert, mdiClose });
  },

  beforeDestroy() {
    if (this.resolvePrompt) {
      // Not sure if I need this here, but I think I did see a call to 'prompt' hang once without it...
      this.resolvePrompt();
      this.resolvePrompt = null;
    }
  },

  methods: {
    async prompt(opts={}) {
      // 'opts' is an optional object containing 'title' and 'subtitle' if you want to override the slots
      const prev = this.opts;
      this.opts = opts || {};
      this.onInput(true);
      await new Promise(r => this.resolvePrompt = r);
      this.opts = prev;

      // Signing in may trigger the app to resolve the new user's pending order. Make sure to wait for that before
      // continuing any user flows.
      // TODO: this should probably use a global property instead of going fishing into the '_vnode'
      // const app = this.$root._vnode.componentInstance;
      // if (app.resolvePendingOrderStatus.pending)
      //   await app.resolvePendingOrderStatus.promise;
    },

    signIn(jwt, method) {
      this.global.jwt = jwt;
      this.onInput(false);
      this.$emit('sign-in');
    },

    async signOut() {
      // this.global.jwt = await this.$call('get-jwt', { method: 'ANON' });
      this.global.jwt = null;
    },

    onInput(v) {
      if (this.$refs['sign-in-email'])
        this.$refs['sign-in-email'].reset();
      this.$emit('input', v);
    }
  },

  watch: {
    value(v) {
      if (!v && this.resolvePrompt) {
        this.resolvePrompt();
        this.resolvePrompt = null;
      }
    }
  }

}
</script>
