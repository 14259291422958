import globalStorage from '@/mixins/global-storage.js';


async function hasAudio(src) {
  // It is ridiculously hard to determine if a video file has an audio track. This method works by trying to loading
  // the entire 'src' into an AudioContext and then trying to decode audio data from that context. If it fails, there's
  // no audio data. I only use thie for local blobs sources, where we already have the entire file so it's fast. I
  // would not suggest using it on remote sources. This doesn't even work reliably cross-platform. Works for Chrome,
  // but not iOS.
  // Helpful (but inadequate) links:
  // https://stackoverflow.com/questions/21270048/html5-video-how-to-detect-when-there-is-no-audio-track
  // https://stackoverflow.com/questions/49140159/extracting-audio-from-a-video-file
  const ctx = new (window.AudioContext || window.webkitAudioContext)();
  const blob = await fetch(src).then(response => response.blob());
  const buffer = await new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsArrayBuffer(blob);
  });
  try {
    await ctx.decodeAudioData(buffer);
    return true;
  } catch (e) {
    return false;
  }
}


// Don't use this to test if the 'src' exists
async function getMetadata(src) {
  const el = document.createElement('video');
  await new Promise((resolve, reject) => {
    el.addEventListener('loadedmetadata', resolve);
    el.addEventListener('error', reject);
    el.src = src;
  });

  const output = { w: el.videoWidth, h: el.videoHeight, duration: el.duration };
  if (!output.w || !output.h)
    throw new Error('Could not get bounds from metadata');

  if (el.duration === Infinity) {
    // A workaround for this bug:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=642012
    const onTimeUpdate = new Promise(resolve => el.addEventListener('timeupdate', resolve));
    el.currentTime = Number.MAX_SAFE_INTEGER;
    await onTimeUpdate;
    output.duration = el.duration;
  }

  return output;
}


window.mp4SrcCache = window.mp4SrcCache || new Set();
async function getOptimizedSrc(src, suffix='.mp4') {
  if (!src)
    return null;
  if (src.startsWith('blob:') || src.endsWith(suffix))
    return src;
  const global = globalStorage.data().global;
  if (global.localBlobSrcMap[src])
    return global.localBlobSrcMap[src];
  if (mp4SrcCache.has(src))
    return src + suffix;
  if ((await fetch(src + suffix, { method: 'HEAD' })).status == 200) {
    mp4SrcCache.add(src);
    return src + suffix;
  }
  return src;
}


window.blobSrcCache = window.blobSrcCache || {};
async function getOptimizedBlobSrc(src, suffix='.mp4') {
  const optimized = await getOptimizedSrc(src, suffix);
  if (optimized.startsWith('blob:'))
    return optimized;
  if (!blobSrcCache[optimized])
    blobSrcCache[optimized] = URL.createObjectURL(await fetch(optimized).then(r => r.blob()));
  return blobSrcCache[optimized];
}


export { getMetadata, getOptimizedSrc, getOptimizedBlobSrc };
