function _buildKey(key) {
  return 'ellacard.' + key;
}

function isAvailable() {
  // Local storage can be explicitly disabled by setting ?storage=false in the query string
  const flag = (new URLSearchParams(location.search)).get('storage');
  if ([ 'true', 'false' ].includes(flag)) {
    if (flag == 'false')
      return false;
  } else if (window !== window.parent) {
    // iframes cannot use local storage unless the 'storage' query string parameter is explicitly set to 'true'
    return false;
  }
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
}

function has(key) {
  if (!isAvailable())
    return false;
  try {
    return localStorage.getItem(_buildKey(key)) != null;
  } catch (e) {
    // localStorage is not available
  }
  return false;
}

function get(key) {
  if (!isAvailable())
    return null;
  try {
    const value = localStorage.getItem(_buildKey(key));
    try {
      return (value == null) ? null : JSON.parse(value);
    } catch (e) {
      // Non-JSON item in localStorage - not allowed
      localStorage.removeItem(_buildKey(key));
    }
  } catch (e) {
    // localStorage is not available
  }
  return null;
}

function set(key, value) {
  if (!isAvailable())
    return false;
  try {
    const serializedValue = JSON.stringify(value);
    if (serializedValue == localStorage.getItem(_buildKey(key)))
      // Make sure we only set the value if we need to, otherwise we'll overzealously trigger 'storage' events
      return true;

    localStorage.setItem(_buildKey(key), serializedValue);
    return true;
  } catch (e) {
    return false;
  }
}

function remove(key) {
  if (!isAvailable())
    return false;
  try {
    localStorage.removeItem(_buildKey(key));
    return true;
  } catch (e) {
    return false;
  }
}

function clear() {
  if (!isAvailable())
    return false;
  try {
    localStorage.clear();
    return true;
  } catch (e) {
    return false;
  }
}

export default { has, get, set, remove, clear, isAvailable };
