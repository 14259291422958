<template>
  <div>
    <div class="d-flex justify-center">
      <div class="d-flex" style="width: 100%; max-width: 320px;">
        <v-btn icon large @click="reset()">
          <v-icon>{{mdiRefreshCircle}}</v-icon>
        </v-btn>
        <v-spacer />
        <template v-for="i in 4">
          <v-spacer v-if="i != 1" />
          <v-text-field
            ref="codeDigit"
            class="code-digit-input flex-grow-0"
            dense outlined
            type="tel"
            v-mask="'#'"
            :name="'Code digit ' + i"
            autocomplete="-"
            :autofocus="i == 1"
            :value="code[i - 1]"
            @input="v => { $set(code, i - 1, v); tryAdvanceFocus(i); }"
            @keydown.enter="(i == 4) && $emit('verify', joinedCode)"
            @keydown.delete.prevent="deleteDigit(i)"
            @click="select(i)"
            @paste.stop.prevent="e => onPaste(e, i)"
          />
        </template>
        <v-spacer />
        <v-btn
          v-if="invalid"
          icon large color="error"
          @click="resetCode()"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon large color="primary"
          :loading="busy"
          :disabled="code.find(i => !i)"
          @click="$emit('verify', joinedCode)"
        >
          <v-icon>{{mdiCheckCircle}}</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="v-messages mt-n5">
      <div class="v-messages__wrapper">
        <div class="v-messages__message d-flex justify-center">
          <div v-if="invalid" class="error--text">Invalid code</div>
          <div v-else>
            <div>Check your {{type}} and enter the code here</div>
            <div v-if="type == 'email'" class="mt-1">
              <router-link :to="{ name: 'faq', hash: '#never-received-sign-in-email' }" target="_blank">
                Didn't receive an email?
              </router-link>
            </div>
          </div>
          <v-progress-circular class="ml-1" size="12" width="2" rotate="-90" :value="expPct" />
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.code-digit-input {
  width: 40px;
}
.code-digit-input input {
  text-align: center;
}
</style>


<script>
import { mdiRefreshCircle, mdiCheckCircle, mdiClose } from '@mdi/js';


export default {
  name: 'code-input',

  props: [ 'busy', 'invalid', 'exp', 'type' ],

  data() {
    return {
      code: [ '', '', '', '' ],

      // For managing the time left to enter the temporary code
      expPct: 0,
      expInterval: null
    };
  },

  computed: {
    joinedCode() {
      return this.code.join('');
    }
  },

  created() {
    Object.assign(this, { mdiRefreshCircle, mdiCheckCircle, mdiClose });
  },

  beforeDestroy() {
    this.reset();
  },

  methods: {
    resetCode() {
      this.code = [ '', '', '', '' ];
      this.$emit('reset-code');
    },

    reset() {
      this.resetCode();
      clearInterval(this.expInterval);
      this.$emit('reset');
    },

    tryAdvanceFocus(i) {
      if (!this.code[i - 1])
        return;
      this.$refs.codeDigit[i - 1].$el.querySelector('input').blur();
      if (i < 4)
        this.select(i + 1);
    },

    async deleteDigit(i) {
      if (this.code[i - 1] || i == 1) {
        this.$set(this.code, i - 1, '');
        return;
      }
      this.$set(this.code, i - 2, '');
      await this.$nextTick();
      this.select(i - 1);
    },

    select(i) {
      if (this.code[i - 1]) {
        const el = this.$refs.codeDigit[i - 1].$el.querySelector('input').parentNode;
        getSelection().selectAllChildren(el);
      }
      this.$refs.codeDigit[i - 1].$el.querySelector('input').focus();
    },

    async onPaste(e, i) {
      let text = e.clipboardData.getData('text/plain');
      if (text) {
        text = text.trim();
        if (text.length > 5 - i)
          // We pasted too much to fill the boxes
          return;
        if (!/^[\d]+$/.test(text))
          // This text contains some non-digits
          return;
        for (let j = 0; j < text.length; j++)
          this.$set(this.code, i + j - 1, text[j]);
        if (i + text.length - 1 == 4)
          return;
        await this.$nextTick();
        this.select(i + text.length);
      }
    }
  },

  watch: {
    joinedCode(v) {
      if (v && v.length == 4)
        this.$emit('verify', v);
    },

    exp: {
      immediate: true,
      handler(value) {
        clearInterval(this.expInterval);
        const sentAt = new Date().getTime();
        const total = (value * 1000) - sentAt;
        this.expInterval = setInterval(
          () => {
            this.expPct = 100 * (new Date().getTime() - sentAt) / total;
            if (this.expPct >= 100) {
              this.reset();
            }
          },
          1000
        );
      }
    }
  }

}
</script>
