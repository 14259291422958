<template>
  <v-overlay opacity=".8" z-index="999" :value="true">
    <div>
      <v-btn style="position: fixed; top: 12px; right: 12px;" light fab @click="$emit('cancel')">
        <v-icon v-html="mdiClose" />
      </v-btn>
      <!-- Note that we're careful NOT to have a transition before showing this element in its final position - that could cause the recaptcha image challenge dialog to get all out of whack, especially on mobile -->
      <div id="captcha" />
    </div>
  </v-overlay>
</template>

<script>
import { mdiClose } from '@mdi/js';

export default {
  name: 'captcha-overlay',

  created() {
    Object.assign(this, { mdiClose });
  },

  async mounted() {
    // The Google captcha challenge is shown with an absolute positioning with its 'top' property set relative to the
    // body. We do set the body's 'top' when locking background scrolling if the page is already partially scrolled,
    // which results in the challenge getting cut off, so we need to be careful to temporarily unset that property
    // here. This would also apply to 'margin-top' if we used that property.
    if (document.body.style.top) {
      this.previousTop = document.body.style.top;
      document.body.style.top = '0px';
    }

    await loadRecaptchaPromise;
    grecaptcha.render(
      'captcha',
      { sitekey: '6Lfn0PEaAAAAAE37Uc4qkTBHLdkp7_BMuByo7rjb', callback: x => this.$emit('complete', x) }
    );
  },

  beforeDestroy() {
    if (this.previousTop)
      document.body.style.top = this.previousTop;
  }

}
</script>
