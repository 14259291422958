const page = { W: 600, H: 840 };

const blob = {
  BTN: { MIN_W: 64, MIN_H: 88, MAX_W: page.W, MAX_H: 88 },
  GIFTCARD: { MIN_W: 337, MIN_H: 212.5, MAX_W: page.W, MAX_H: page.W },
  GRADIENT: { MIN_W: 80, MIN_H: 80, MAX_W: page.W, MAX_H: page.H, ALIGN_EDGES_OFFSET: 0 },
  GROUP_VIDEO: { MIN_W: 240, MIN_H: 240, MAX_W: page.W, MAX_H: page.W },
  IMG: { MIN_W: 80, MIN_H: 80, MAX_W: page.W, MAX_H: page.H },
  TEXT: { MIN_W: 80, MIN_H: 18, MAX_W: page.W - 20, MAX_H: page.H - 20, MIN_SIZE: 18, ALIGN_EDGES_OFFSET: 10 },
  VIDEO: { MIN_W: 240, MIN_H: 180, MAX_W: page.W, MAX_H: page.H },
};

function constrain(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

const DEGREE_STEP = 5;
function roundDegrees(value) {
  return Math.round(value / DEGREE_STEP) * DEGREE_STEP;
}

export default { page, blob, constrain, roundDegrees };
