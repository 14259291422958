import uuid4 from '@/utils/uuid.js';

let CACHE = null;
let CACHE_KEY = null;
let CACHE_RELOAD_KEY = null;

let CHANNEL;
try {
  CHANNEL = new BroadcastChannel('pending-orders-reload');
} catch (e) {
}

export default {

  data() {
    return {
      pendingOrders: [],
      loadPendingOrdersStatus: this.$track('loadPendingOrders'),
      updatePendingOrderStatus: this.$track('updatePendingOrder'),
      deletePendingOrderStatus: this.$track('deletePendingOrder')
    };
  },

  created() {
    if (CHANNEL)
      CHANNEL.addEventListener('message', this.pendingOrdersMessageHandler);
  },

  beforeDestroy() {
    if (CHANNEL)
      CHANNEL.removeEventListener('message', this.pendingOrdersMessageHandler);
  },

  methods: {
    async loadPendingOrders() {
      if (!CACHE || (CACHE_KEY != CACHE_RELOAD_KEY)) {
        CACHE_KEY = CACHE_RELOAD_KEY;
        CACHE = new Promise(
          async r => {
            if (!this.isSignedIn)
              return r([]);
            const pending = await this.$call('list-pending-orders');
            pending.sort((a, b) => new Date(b[1].updated_at).getTime() - new Date(a[1].updated_at).getTime());
            r(pending);
          }
        );
      }
      this.pendingOrders = await CACHE;
    },

    onUpdatePendingOrder(order_id, did_delete=false) {
      CACHE_RELOAD_KEY = this.globalUserId + uuid4();
      try {
        CHANNEL.postMessage(JSON.stringify({ CACHE_RELOAD_KEY, order_id, did_delete }));
      } catch (e) {
      }
    },

    async updatePendingOrder(params) {
      const r = await this.$call('update-pending-order', params);
      this.onUpdatePendingOrder(r.order_id);
      return r;
    },

    async deletePendingOrder(params) {
      const { order_id } = params;
      const r = await this.$call('delete-pending-order', params);
      this.onUpdatePendingOrder(order_id, true);
      return r;
    },

    pendingOrdersMessageHandler(e) {
      const { CACHE_RELOAD_KEY, order_id, did_delete } = JSON.parse(e.data);
      this.loadPendingOrders();
      if (this.onExternalUpdatePendingOrder)
        this.onExternalUpdatePendingOrder(order_id, did_delete);
    }
  },

  watch: {
    globalUserId(v) {
      CACHE_RELOAD_KEY = v + uuid4();
    }
  }
};
