<template>
  <!-- Note that the 'key' is actually very important as component re-use sometimes results in a weird bug where the slotted content doesn't get rendered, as in the left buttons in the card-editor when clicking directly between a text and image blob. Setting it to the 'msg' property is NOT a perfect solution, but it does work in our case. -->
  <!-- Important: if you use this component in combination with some other component that also takes an activator slot (such as a v-menu), you should use the tooltip-btn as the outer component, and you must provide a unique 'key' for it as well, otherwise, the position of the tooltip could be off when using the 'persistent' prop -->
  <v-tooltip :key="msg" v-bind="$attrs" v-on="$listeners" eager max-width="300" v-model="show">
    <template v-slot:activator="on">
      <slot :on="{ touchstart, mouseenter, mouseleave }" :bind="{ disabled }"></slot>
    </template>

    <!-- TODO: make it so that when you click on the tooltip itself, it also clicks on the button. Seems like the easiest way to do this is to actually make the tooltip part of the button itself (display it from inside the button). This would also solve your nightmares with scrolling around / repositioning elements... You hed experimented with using something like the following (doesn't work):
    test(e) {
      const btn = this.$children[0].$children[0];
      btn.$emit('click.stop', new PointerEvent(e.type, e));
    }
    -->
    <!--
    <div
      v-if="persistent"
      class="mx-n4 my-n2 px-4 py-2"
      style="pointer-events: auto; cursor: pointer;"
      @click="$emit('click-persistent-tooltip')"
    >
      {{msg}}
    </div>
    -->

    <span>{{msg}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'tooltip-btn',

  props: {
    msg: String,
    disabled: Boolean,
    autoHide: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      show: false
    };
  },

  methods: {
    touchstart() {
      this.show = true;
    },

    mouseenter() {
      this.show = true;
    },

    mouseleave() {
      this.show = false;
    }
  },

  watch: {
    disabled(v) {
      if (v)
        this.show = false;
    },

    show(v) {
      // This ensures that the tooltip will eventually get hidden to account for bugs like this one:
      // https://github.com/vuetifyjs/vuetify/issues/12052
      clearTimeout(this.hideTimeout);
      if (v)
        this.hideTimeout = v && this.autoHide && setTimeout(() => this.show = false, 3000);
    }
  }
}
</script>
