// These are all the styles that might influence the width of text
const TEXT_STYLE_KEYS = [
  'font-size', 'font-family', 'font-style', 'font-weight', 'letter-spacing', 'text-transform', 'text-shadow'
];

function inputInLineAdjust(root) {
  // Use setTimeout to make sure that the value has been explicitly set on the input
  setTimeout(
    () => {
      const inputNode = root.getElementsByTagName('input')[0];
      const labelNode = root.getElementsByTagName('label')[0];

      const toMeasure = inputNode.value || inputNode.placeholder || (labelNode && labelNode.innerHTML) || '';

      // Create a node to measure the width of the input text (make sure it has the same style to ensure our
      // measurement is accurate). Note that we deliberately attach this temporary node directly to the body instead of
      // the root element in case the root element is hidden (in which case the width would be 0)
      const measureTextNode = document.createElement('div');
      const style = getComputedStyle(inputNode);
      TEXT_STYLE_KEYS.forEach(
        key => measureTextNode.style.setProperty(key, style.getPropertyValue(key), style.getPropertyPriority(key))
      );
      measureTextNode.style['position'] = 'fixed';
      measureTextNode.style['color'] = 'transparent';
      measureTextNode.style['white-space'] = 'pre';
      measureTextNode.style['user-select'] = 'none';
      document.body.appendChild(measureTextNode);
      measureTextNode.innerHTML = toMeasure;

      // Add extra pixels to the width - input is jittery without this
      inputNode.style['width'] = (measureTextNode.offsetWidth + 2) + 'px';

      document.body.removeChild(measureTextNode);
    }
  );
}

// Use with 'v-text-field' to allow it to grow / expand based on the width of the input value
// If there is no value, the input will shrink all the way down to match the width of the label. If there is no label,
// the input will effectively disappear, so make sure to set a min-width on the element in that case.
export default {
  inserted(root, binding, vnode) {
    const labelNode = root.getElementsByTagName('label')[0];
    if (labelNode)
      // We can shrink so much as to make the label illegible, which is likely undesirable
      labelNode.style['overflow'] = 'visible';
    inputInLineAdjust(root);
  },

  componentUpdated(root, binding, vnode) {
    inputInLineAdjust(root);
  }
};
