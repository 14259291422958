<template>
  <!-- It can be a little annoying to navigate using the hover effect from the side menu, you may want to make this change: :open-on-hover="!click && !persistent && header" -->
  <component
    :is="$vuetify.breakpoint.xsOnly ? VDialog : VMenu"
    v-no-scroll
    eager content-class="nav-menu-content prominent"
    v-bind="$vuetify.breakpoint.xsOnly ? {} : {
      'max-width': '100vw',
      'open-on-hover': !click && !persistent,
      'close-on-click': !persistent,
      'close-on-content-click': !persistent,
      bottom: header,
      'offset-y': header,
      left: !header,
      'offset-x': !header,
      'nudge-right': header ? 0 : 1
    }"
    v-model="value"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="header"
        v-blur
        v-bind="attrs" v-on="on"
        class="header-menu font-weight-bold my-3 mx-1 px-2"
        style="border-radius: 4px;"
        text color="ec-dark-blue"
        :disabled="disabled"
        @click="click = !click"
      >
        {{name}}
        <v-icon class="mr-n1" v-html="mdiMenuDown" />
      </v-btn>
      <v-list-item
        v-else
        v-blur
        v-bind="attrs" v-on="on"
        :class="(disabled ? 'grey' : 'ec-dark-blue') + '--text'"
        :disabled="disabled"
        @click="click = !click"
      >
        <v-icon class="ml-n2 hidden-xs-only" color="ec-dark-blue" v-html="mdiMenuLeft" />
        <v-list-item-title class="text-body-2">
          {{name}}<span class="hidden-sm-and-up">…</span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <div class="scroll-container overflow-auto" v-bind:class="{ header, fill: fillWidth }">

      <template v-if="$vuetify.breakpoint.xsOnly">
        <div class="pa-3 d-flex align-center font-weight-bold">
          <div class="flex-grow-1 grey--text text--darken-2">{{name}}</div>
          <v-btn icon @click.capture.stop.prevent="value = false">
            <v-icon v-html="mdiClose" />
          </v-btn>
        </div>
        <v-divider />
      </template>

      <!--
      <v-card class="ec-dark-blue--text nav-menu-header pa-3" flat tile :to="to">
        <v-btn class="float-right ml-2 mb-2" icon @click.capture.stop.prevent="value = false">
          <v-icon v-html="mdiClose" />
        </v-btn>
        <div class="text-h6 text-sm-h5" style="line-height: 1.5;" v-html="title" />
        <div class="mt-1 mb-4" v-html="subtitle" />
        <v-btn class="px-2" dark color="primary">
          <v-icon v-html="icon" />
          <span class="mx-2" style="text-transform: none;" v-html="action" />
          <v-icon v-html="mdiChevronRight" />
        </v-btn>
      </v-card>
      <v-divider />
      -->
      <slot />
    </div>
  </component>
</template>


<style scoped>
.scroll-container {
  max-width: 100%;
  max-height: 100%;
  overscroll-behavior: contain;
}

.scroll-container.fill {
  width: 100vw;
}

@media (min-width: 600px) {
  .scroll-container {
    max-width: calc(100vw - 267px);
    max-height: calc(100vh - 32px);
  }
  .scroll-container.header {
    max-width: calc(100vw - 24px);
    max-height: calc(100vh - 120px);
  }
}

.header-menu {
  height: unset !important;
  text-transform: none;
  border-bottom: 2px solid $ec-blue;
  border-radius: 0;
  transition: border-bottom-color .1s, color .1s;
}

.header-menu.theme--light.v-btn::before {
  display: none;
}

.header-menu.v-btn--disabled {
  border-bottom-color: rgba(0, 0, 0, 0.26);
}

.nav-menu-header {
  transition: background-color .3s;
}

.nav-menu-header:hover {
  background-color: #EEEEEE;
}
</style>


<style>
.nav-menu-content {
  background: #FFFE;
  backdrop-filter: blur(18px);
}
</style>


<script>
import { VDialog, VMenu } from 'vuetify/lib';
import { mdiChevronRight, mdiClose, mdiMenuDown, mdiMenuLeft } from '@mdi/js';

export default {
  name: 'nav-menu',

  props: {
    name: String,
    title: String,
    subtitle: String,
    header: Boolean,
    disabled: Boolean,
    action: String,
    icon: String,
    to: Object,
    fillWidth: Boolean
  },

  data() {
    return {
      value: false,
      click: false,
      persistent: false
    };
  },

  created() {
    Object.assign(this, { VDialog, VMenu, mdiChevronRight, mdiClose, mdiMenuDown, mdiMenuLeft });
  },

  watch: {
    click(v) {
      this.$emit('spotlight', v);
    },

    value(v) {
      if (!v)
        this.click = false;
    }
  }
}
</script>
