var render = function render(){
  var _vm$blob$btn;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "resize-box absolute-fill",
    class: {
      'as-override': !_vm.isPrimary && !_vm.isCropping,
      'as-crop': _vm.isCropping
    }
  }), _vm.canTransform ? [_vm._l([[-1, -1], [1, -1], [1, 1], [-1, 1]], function (_ref) {
    var x = _ref[0],
      y = _ref[1];
    return _vm.blob.type != 'BTN' ? _c('div', {
      key: `${x}${y}`,
      staticClass: "corner-btn",
      class: {
        'as-crop': _vm.isCropping
      },
      style: {
        left: x < 0 ? -_vm.btnOffset + 'px' : undefined,
        right: x > 0 ? -_vm.btnOffset + 'px' : undefined,
        top: y < 0 ? -_vm.btnOffset + 'px' : undefined,
        bottom: y > 0 ? -_vm.btnOffset + 'px' : undefined
      },
      on: {
        "mousedown": function (e) {
          return _vm.$emit('resize', e);
        },
        "touchstart": function (e) {
          return _vm.$emit('resize', e);
        }
      }
    }, [_c('v-btn', {
      directives: [{
        name: "blur",
        rawName: "v-blur"
      }],
      staticClass: "ma-3",
      attrs: {
        "fab": "",
        "x-small": "",
        "absolute": "",
        "color": "red",
        "disabled": _vm.isShowingVirtualKeyboard
      }
    })], 1) : _vm._e();
  }), _vm.isCropping ? [_c('div', {
    staticClass: "absolute-fill d-flex align-end",
    staticStyle: {
      "bottom": "0"
    },
    on: {
      "!click": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
    }
  }, [_c('v-slider', {
    staticClass: "crop-rotate-slider",
    staticStyle: {
      "pointer-events": "auto"
    },
    attrs: {
      "min": "-45",
      "max": "45",
      "hide-details": "",
      "color": "transparent",
      "track-color": "transparent",
      "thumb-label": "always",
      "thumb-color": "amber accent-4",
      "thumb-size": 44,
      "step": _vm.snap ? 5 : 1,
      "value": _vm.blob.crop.r > 45 ? _vm.blob.crop.r - 360 : _vm.blob.crop.r
    },
    on: {
      "input": function (v) {
        return _vm.blob.crop.r = v < 0 ? 360 + v : v;
      }
    },
    scopedSlots: _vm._u([{
      key: "thumb-label",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "white"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.mdiCropRotate)
          }
        })];
      },
      proxy: true
    }], null, false, 3591544664)
  })], 1)] : [!((_vm$blob$btn = _vm.blob.btn) !== null && _vm$blob$btn !== void 0 && _vm$blob$btn.form) && (!['GIFTCARD', 'GROUP_VIDEO'].includes(_vm.blob.type) || _vm.mode == 'purchase' || _vm.blob.sample) ? _c('v-btn', {
    directives: [{
      name: "blur",
      rawName: "v-blur"
    }],
    staticStyle: {
      "left": "calc(50% - 28px)",
      "top": "-96px",
      "pointer-events": "auto"
    },
    attrs: {
      "fab": "",
      "absolute": "",
      "color": "red"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('delete');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiDelete)
    }
  }), _c('span', {
    staticClass: "btn-tooltip bottom"
  }, [_vm._v("Delete")])], 1) : _vm._e(), _c('v-btn', {
    directives: [{
      name: "blur",
      rawName: "v-blur"
    }],
    staticStyle: {
      "left": "calc(50% - 28px)",
      "bottom": "-96px",
      "pointer-events": "auto"
    },
    attrs: {
      "fab": "",
      "absolute": "",
      "color": "red",
      "disabled": _vm.isShowingVirtualKeyboard
    },
    on: {
      "mousedown": function (e) {
        return _vm.$emit('rotate', e);
      },
      "touchstart": function (e) {
        return _vm.$emit('rotate', e);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiRotateRight)
    }
  }), _c('span', {
    staticClass: "btn-tooltip top"
  }, [_vm._v("Drag to rotate")])], 1)]] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }