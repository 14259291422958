<template>
  <div
    class="absolute-fill"
    v-bind:style="{
      background: `linear-gradient(${blob.gradient.r}deg, ${blob.gradient.start}, ${blob.gradient.stop})`
    }"
  />
</template>

<script>
export default {
  name: 'gradient-blob',
  props: [ 'blob' ]
}
</script>
