import Vue from 'vue';
import VueRouter from 'vue-router';
import globalStorageMixin from '@/mixins/global-storage.js';
import { waitHydrated } from '@/mixins/ssr.js';

function onNavigationFailure(e) {
  // Ignore errors of these types:
  // https://v3.router.vuejs.org/guide/advanced/navigation-failures.html#navigationfailuretype
  for (const key of [ 'duplicated', 'redirected', 'aborted' ])
    if (VueRouter.isNavigationFailure(e, VueRouter.NavigationFailureType[key]))
      return e.to;
  throw e;
}

const _push = VueRouter.prototype.push;
VueRouter.prototype.push = function push(routerLocation) {
  return _push.call(this, routerLocation).catch(onNavigationFailure);
}

Vue.use(VueRouter);

// https://www.digitalocean.com/community/tutorials/vuejs-lazy-loading-vue-cli-3-webpack
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

function loadComponent(component) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/components/${component}.vue`);
}

// A regex that you can use with route params to match UUIDs only
const UUID = '([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})';

// In every case except these, the category header can be directly created from the legacy 'start' category by
// appending the suffix "-cards"
const START_CATEGORY_REDIRECTS = { baby: 'new-baby', condolences: 'sympathy', congrats: 'congratulations' };

const routes = [
  { path: '/', name: 'default',
    redirect(to) {
      return { name: globalStorageMixin.data().global.jwt ? 'catalog' : 'start' };
    }
  },
  { path: '/start', name: 'start', component: loadView('Start') },

  { path: '/start/group-cards', name: 'start-group-cards', component: loadView('StartGroupCards') },
  { path: '/start/invitations', name: 'start-invitations', component: loadView('StartInvitations') },
  { path: '/start/gift-cards', name: 'start-gift-cards', component: loadView('StartGiftCards') },
  { path: '/start/holidays', name: 'start-holidays', component: loadView('StartHolidays') },
  { path: '/start/video-maker', name: 'start-video-maker', component: loadView('StartVideoMaker') },
  { path: '/start/recognition', name: 'start-recognition', component: loadView('StartRecognition') },
  { path: '/start/automation', name: 'start-automation', component: loadView('StartAutomation') },
  { path: '/start/bulk-email', name: 'start-bulk-email', component: loadView('StartBulkEmail') },
  { path: '/start/embedding-api', name: 'start-embedding-api', component: loadView('StartEmbeddingApi') },
  { path: '/start/business', name: 'start-business', component: loadView('StartBusiness') },

  // Defunct / not maintained
  { path: '/start/yearbooks', name: 'start-yearbooks', component: loadView('StartYearbooks') },
  { path: '/physical-virtual-cards', name: 'physical-virtual-cards', component: loadView('PhysicalVirtualCards') },

  // { path: `/videos/:templateId${UUID}?`, name: 'catalog-video', props: true, component: loadView('CatalogVideo') },
  { path: `/videos`, name: 'catalog-video', component: loadView('CatalogVideo'),
    children: [
      { path: `:templateId${UUID}`, name: 'video-template', props: true, component: loadView('VideoThemeDialog') }
    ]
  },

  { path: `/cards/:selectedId${UUID}?`, name: 'catalog', props: true, component: loadView('Catalog') },
  { path: '/categories', name: 'catalog-categories', props: true, component: loadView('CatalogCategories') },
  { path: `/categories/:category/:selectedId${UUID}?`, name: 'catalog-category', props: true, component: loadView('CatalogCategory') },
  { path: '/gift-cards/:providerId?', name: 'catalog-gift-cards', props: true, component: loadView('CatalogGiftCards') },
  { path: `/project/:orderId`, name: 'project', props: true, component: loadView('Project') },
  { path: '/account', name: 'account', component: loadView('Account'),
    children: [
      { path: 'overview', name: 'account-overview', component: loadView('AccountOverview') },
      { path: 'orders/:orderId?', name: 'account-orders', props: true, component: loadView('AccountOrders') },
      { path: 'signed', name: 'account-signed', component: loadView('AccountSigned') },
      { path: 'received', name: 'account-received', component: loadView('AccountReceived') },
      { path: 'contacts', name: 'account-contacts', component: loadView('AccountContacts') },
      { path: 'design', name: 'account-design', component: loadView('AccountDesign'),
        redirect: { name: 'account-design-cards' },
        children: [
          { path: 'overview', name: 'account-design-overview', component: loadView('AccountDesignOverview') },
          { path: 'cards/:designId?', name: 'account-design-cards', props: true, component: loadView('AccountDesignCards') },
          { path: 'videos/:templateId?', name: 'account-design-videos', props: true, component: loadView('AccountDesignVideos') }
        ]
      },
      { path: 'org', name: 'account-org', component: loadView('AccountOrg'),
        redirect: { name: 'account-org-overview' },
        children: [
          { path: 'overview', name: 'account-org-overview', component: loadView('AccountOrgOverview') },
          { path: 'users', name: 'account-org-users', component: loadView('AccountOrgUsers') },
          { path: 'user-activity', name: 'account-org-user-activity', component: loadView('AccountOrgUserActivity') },
          { path: 'orders', name: 'account-org-orders', component: loadView('AccountOrgOrders') },
          { path: 'unsubscribes', name: 'account-org-unsubscribes', component: loadView('AccountOrgUnsubscribes') },
          { path: 'branding', name: 'account-org-branding', component: loadView('AccountOrgBranding') },
          { path: 'embedding', name: 'account-org-embedding', component: loadView('AccountOrgEmbedding') },
          { path: 'keys', name: 'account-org-keys', component: loadView('AccountOrgKeys') }
        ]
      }
    ]
  },
  { path: '/design', name: 'design', props: true, component: loadView('Design') },
  { path: '/video-template-docs', name: 'video-template-docs', component: loadView('VideoTemplateDocs') },
  { path: '/api-docs', name: 'api-docs', component: loadView('APIDocs') },
  { path: '/business', name: 'business', component: loadView('Business') },
  { path: '/featured/index', name: 'featured-index', component: loadView('FeaturedIndex') },
  { path: `/featured/:id${UUID}?`, name: 'featured', props: true, component: loadView('Featured') },
  { path: '/admin', name: 'admin', component: loadView('Admin'),
    children: [
      { path: 'review', name: 'admin-review', component: loadView('AdminReview'),
        children: [
          { path: 'card/:designId', name: 'admin-review-card', props: true, component: loadView('AdminReviewCard') },
          { path: 'video/:templateId', name: 'admin-review-video', props: true, component: loadView('AdminReviewVideo') }
        ]
      },
      { path: 'user', name: 'admin-user', component: loadView('AdminUser'),
        children: [
          { path: ':userId', name: 'admin-user-details', props: true, component: loadView('AdminUserDetails') }
        ]
      },
      { path: 'order/:token?', name: 'admin-order', component: loadView('AdminOrder') },
      { path: 'tracking', name: 'admin-tracking', props: true, component: loadView('AdminTracking') },
      { path: 'domain-risks', name: 'admin-domain-risks', component: loadView('AdminDomainRisks') },
      { path: 'orgs/:orgId?', name: 'admin-orgs', props: true, component: loadView('AdminOrgs') },
      { path: 'promo-codes', name: 'admin-promo-codes', component: loadView('AdminPromoCodes') },
      { path: 'payments', name: 'admin-payments', component: loadView('AdminPayments') },
      { path: 'manage-catalog', name: 'admin-manage-catalog', component: loadView('AdminCatalog') },
      { path: 'manage-gift-card-catalog', name: 'admin-manage-gift-card-catalog', component: loadView('AdminGiftCardCatalog') },
      { path: 'gift-cards', name: 'admin-gift-cards', component: loadView('AdminGiftCards') },
      { path: 'gift-card-claims', name: 'admin-gift-card-claims', component: loadView('AdminGiftCardClaims') },
      { path: 'categories/:name?', name: 'admin-categories', props: true, component: loadView('AdminCatalogHeaders') },
      { path: 'designers', name: 'admin-designers', component: loadView('AdminDesigners') },
      { path: 'unsubscribes', name: 'admin-unsubscribes', component: loadView('AdminUnsubscribes') },
      { path: 'faq-editor', name: 'admin-faq-editor', component: loadView('AdminFAQEditor') },
      { path: 'blog-editor/:id?', name: 'admin-blog-editor', props: true, component: loadView('AdminBlogEditor') },
      { path: 'pricing', name: 'admin-pricing', component: loadView('AdminPricing') },
      { path: 'customer-segments', name: 'admin-customer-segments', component: loadView('AdminCustomerSegments') },
      { path: 'analytics', name: 'admin-analytics', component: loadView('AdminAnalytics') },
      { path: 'web-media', name: 'admin-web-media', component: loadView('AdminWebMedia') }
    ]
  },
  { path: `/sign/:orderId${UUID}`, name: 'card-sign', props: true, component: loadView('CardSign') },
  { path: `/video-project/:projectId${UUID}`, name: 'video-project', props: true, component: loadView('GroupVideoProject') },
  { path: `/open/:readToken${UUID}`, name: 'card-open', props: true, component: loadView('CardOpen') },
  { path: '/img-to-design', name: 'img-to-design', component: loadView('ImgToDesign') },
  { path: '/batch-order', name: 'batch-order', component: loadView('BatchOrder') },
  { path: '/bulk-email', name: 'bulk-email', component: loadView('BulkEmail') },
  { path: `/bulk-email/:campaignId${UUID}`, name: 'bulk-email-campaign', props: true, component: loadView('BulkEmailCampaign') },
  { path: '/file-transfer', name: 'file-transfer', component: loadView('FileTransfer') },
  { path: '/confirm-unsubscribe', name: 'confirm-unsubscribe', component: loadView('ConfirmUnsubscribe') },
  { path: '/unsubscribe', name: 'unsubscribe', component: loadView('Unsubscribe') },
  { path: '/report-sender', name: 'report-sender', component: loadView('ReportSender') },
  { path: '/about', name: 'about', component: loadView('About') },
  { path: '/faq', name: 'faq', component: loadView('FAQ') },
  { path: '/blog', name: 'blog', component: loadView('Blog') },
  { path: '/blog/:urlTitle', name: 'blog-post', props: true, component: loadView('BlogPost') },
  { path: '/pricing', name: 'pricing', component: loadView('Pricing') },
  { path: '/international-gift-cards', name: 'international-gift-cards', component: loadView('InternationalGiftCards') },
  { path: '/privacy-policy', name: 'privacy-policy', component: loadView('PrivacyPolicy') },
  { path: '/cookies', name: 'cookies', component: loadView('Cookies') },
  { path: '/terms-and-conditions', name: 'terms-and-conditions', component: loadView('TermsAndConditions') },
  { path: '/site-map', name: 'site-map', component: loadView('SiteMap') },
  { path: '/release-notes/:date?', name: 'release-notes', props: true, component: loadView('ReleaseNotes') },
  { path: '/social', name: 'social-media', component: loadView('SocialMedia') },
  { path: '/sign-in/:payload', name: 'sign-in', props: true, component: loadView('SignIn') },
  { path: '/notify', name: 'notify', component: loadView('Notify') },

  // We prefix "private" routes with an underscore - these are omitted from indexing in robots.txt
  { path: '/_/integration/:id', name: '_integration', props: true, component: loadView('_Integration') },
  { path: '/_/capture', name: '_capture', component: loadView('_Capture') },
  { path: '/_/print/:token', name: '_print', props: true, component: loadView('_Print') },
  { path: '/_/embed', name: '_embed-root', component: loadView('_EmbedRoot'),
    children: [
      { path: 'checkout', name: 'embed-checkout', component: loadView('EmbedCheckout') },
      { path: 'catalog', name: 'embed-catalog', component: loadView('EmbedCatalog') },
      { path: 'sign/:orderId', name: 'embed-sign', props: true, component: loadView('CardSign') },
      { path: 'open/:readToken', name: 'embed-open', props: true, component: loadView('CardOpen')
      }
    ]
  },
  { path: '/_/reset', name: '_reset',
    redirect() {
      try {
        localStorage.clear();
      } catch (e) {}
      const data = globalStorageMixin.data().global;
      data.jwt = null;
      data.order = null;
      data.localBlobSrcMap = {};
      data.cookies = null;
      return { name: 'default' };
    }
  },
  { path: '/_/embedded-order', name: '_embedded-order',
    redirect() {
      let order;
      try {
        order = JSON.parse(localStorage.getItem('ellacard.embedded_order'));
        delete order.reply_to;
        localStorage.removeItem('ellacard.embedded_order');
      } catch (e) {}
      return { name: 'project', params: { orderId: 'new', order }, hash: '#type' };
    }
  },

  // Legacy redirects
  { path: '/start/business/internal', redirect: { name: 'start-recognition' } },
  { path: '/start/business-internal', redirect: { name: 'start-recognition' } },
  { path: '/start/business/outreach', redirect: { name: 'start-business' } },
  { path: '/start/business-outreach', redirect: { name: 'start-business' } },
  { path: '/start/charity', redirect: { name: 'start-business' } },
  { path: '/start/group-videos', redirect: { name: 'start-video-maker' } },
  { path: '/start/holiday', redirect: { name: 'start-holidays' } },
  { path: '/available-gift-cards', redirect: { name: 'international-gift-cards' } },
  { path: '/account/organization-tools', redirect: { name: 'account-org' } },
  { path: '/account/designs', redirect: { name: 'account-design-cards' } },
  { path: '/design/videos/docs', redirect: { name: 'video-template-docs' } },
  { path: '/design/cards/:designId?', redirect: { name: 'account-design-cards' } },
  { path: '/design/videos/:templateId?', redirect: { name: 'account-design-videos' } },
  { path: '/design/payments', redirect: { name: 'account-design-overview' } },
  { path: '/img-to-card', redirect: { name: 'img-to-design' } },
  { path: '/account/signed/:orderId',
    redirect(to) {
      return { name: 'account-orders', params: to.params, hash: to.hash };
    }
  },
  { path: '/start/:category',
    redirect(to) {
      const category = (START_CATEGORY_REDIRECTS[to.params.category] || to.params.category) + '-cards';
      return { name: 'catalog-category', params: { category } };
    }
  },

  { path: '*', name: 'not-found', component: loadView('RouteNotFound') }
];

function scrollBehavior(to, from, savedPosition) {
  // Hash scrolling is now generally handled by the 'router.afterEach' function below so that we can wait until pages
  // have become hydrated to make sure any target hash IDs exist
  if (savedPosition)
    // The 'savedPosition' is only provided for browser forward / back navigation events
    return savedPosition;
  if (to.matched[0].name == from.matched[0].name)
    // Don't scroll for nested views
    return;
  return { x: 0, y: 0 };
}

const router = new VueRouter({ mode: 'history', routes, scrollBehavior });

const STRIP_QUERY_PREFIXES = [ 'g_ad_' ];
const PERSIST_QUERY_PARAMS = [ 'gclid', 'msclkid' ];

router.beforeEach((to, from, next) => {
  window.SSR_LOADED = router.hydrated = false;
  if (to.query) {
    let found = false;
    const query = { ...to.query };
    for (const key of Object.keys(query))
      for (const prefix of STRIP_QUERY_PREFIXES)
        if (key.startsWith(prefix))
          found = delete query[key];
    if (found) {
      // Make sure we capture the original navigation event before we strip the parameters
      setTimeout(() => $event.log('nav', _.omitBy(JSON.parse(JSON.stringify(_.pick(to, [ 'name', 'params', 'hash', 'query' ]))), _.isEmpty)));
      return next({ name: to.name, params: to.params, hash: to.hash, query });
    }
  }

  if (from.query) {
    const add = {};
    for (const p of PERSIST_QUERY_PARAMS)
      if (from.query[p] && !(to.query || {})[p])
        // Make sure we don't drop persistent query params
        add[p] = from.query[p];
    if (Object.values(add).length)
      return next({ name: to.name, params: to.params, hash: to.hash, query: { ...to.query, ...add } });
  }

  next();
});

function getRouterViewMeta(r) {
  const meta = {
    $index: true,
    url: location.pathname.replace(/\/$/, '').toLowerCase(),
    title: 'Ellacard'
  };
  if (!r)
    return meta;
  let isLast = true;
  const subviews = r.matched.map(x => Object.assign({}, x.components.default.routerViewMeta || {}, x.instances.default?.routerViewMeta || {}));
  for (const { $index, $hide, ...child } of [ ...subviews ].reverse()) {
    if ($index == false)
      meta.$index = false;
    if ($hide)
      meta.$hide = [ ...(meta.$hide || []), ...$hide ];
    if (isLast) {
      Object.assign(meta, child)
      isLast = false;
    }
  }
  for (const { $subtitle } of subviews)
    if ($subtitle)
      meta.title = $subtitle + ' | ' + meta.title;
  return meta;
}

function updateRouteMeta(route) {
  const meta = getRouterViewMeta(route);
  for (const el of document.querySelectorAll('link[rel="canonical"], meta[name="robots"], meta[property*="og:"]'))
    el.remove();
  document.title = meta.title;
  // If provided, the 'url' property also sets the canonical URL in addition to the 'og:url' tag unless a separate
  // '$canonical' value is provided (which could be null / empty / undefined). Note that these properties should not
  // include the location's origin and should start with a "/".
  for (const key of [ 'url', '$canonical' ])
    if (meta[key])
      meta[key] = location.origin + meta[key];
  const canonical = meta.hasOwnProperty('$canonical') ? meta.$canonical : meta.url;
  if (canonical)
    document.head.insertAdjacentHTML('beforeend', `<link rel="canonical" href="${canonical}">`);
  if (!meta.$index)
    document.head.insertAdjacentHTML('beforeend', '<meta name="robots" content="noindex">');
  for (const [ key, value ] of Object.entries(meta))
    if (!key.startsWith('$') && value)
      document.head.insertAdjacentHTML(
        'beforeend',
        `<meta property="og:${key}" content="${value.replace(/[<>"]/g, ' ')}" ${(key == 'description') ? 'name="description"' : ''}>`
      );
}

router.afterEach(async (to, from) => {
  await waitHydrated();
  updateRouteMeta(to);

  // Scroll to any dynamically-loaded hash sections if applicable
  if (to.hash && (to.hash != from.hash))
    // TODO: you have an issue here scrolling to hashes inside parallax-ed elements
    document.getElementById(to.hash.substr(1))?.scrollIntoView({ behavior: 'smooth' });

  window.SSR_LOADED = router.hydrated = true;
  window.dispatchEvent(new Event('ssr-loaded'));

  // https://developers.google.com/analytics/devguides/collection/gtagjs/pages#page_view_event
  gtag('event', 'page_view', { page_title: document.title, page_location: location.href, page_path: to.fullPath });

  // Note that we use JSON parse / stringify to recursively remove all undefined properties from the route object
  $event.log('nav', _.omitBy(JSON.parse(JSON.stringify(_.pick(to, [ 'name', 'params', 'hash', 'query' ]))), _.isEmpty));
});

Vue.util.defineReactive(router, 'hydrated', false);
Object.defineProperty(router, 'currentRouteMeta', { get: () => getRouterViewMeta(router.currentRoute) });

export default router;
