<template>
  <div>
    <error-dialog :status="status" />
    <div ref="btn" class="d-inline-block mb-n1" style="height: 40px; overflow: hidden;" />
  </div>
</template>


<script>
import loadScript from '@/utils/load-script.js';

const client_id = `${process.env.OP_DEV_CONFIG['google-client-id']}.apps.googleusercontent.com`;

export default {
  name: 'sign-in-btn-google',

  props: [ 'subscribe' ],

  data() {
    return {
      status: this.$track('onGoogleSignIn')
    };
  },

  async mounted() {
    await loadScript('https://accounts.google.com/gsi/client');
    google.accounts.id.initialize({ client_id, callback: this.onGoogleSignIn });
    google.accounts.id.renderButton(this.$refs.btn, {});
  },

  methods: {
    async onGoogleSignIn(data) {
      const payload = data.credential;
      const jwt = await this.$call('get-jwt', { method: 'GOOGLE', payload, subscribe: this.subscribe });
      this.$emit('sign-in', jwt);
    }
  }
};
</script>
