<!--
This link contains lots of details concerning video recording - also note that it references taking screenshots, which may be useful if we ever intend to print things
https://www.html5rocks.com/en/tutorials/getusermedia/intro/
-->

<template>
  <div class="absolute-fill d-flex justify-center overflow-hidden" v-bind:style="crop">

    <!-- This is used as an indicator for both loading and video templates -->
    <div
      v-if="loadStatus.pending || (!isPreview && blob.template && !blob.user_id && isEditable && !isSelected)"
      class="absolute-fill template-editable"
      style="z-index: 1;"
    />

    <img
      v-if="isTemplatePlaceholder"
      class="absolute-fill user-select-none"
      width="100%" height="100%" draggable="false"
      :src="blob.video.src"
      @load="$emit('loaded')" @error="$emit('loaded')" @abort="$emit('loaded')"
    />

    <video
      v-show="!isTemplatePlaceholder"
      ref="playback"
      class="playback black"
      v-bind:style="{ transform: blob.video.mirror ? 'scale(-1, 1)' : null }"
      width="100%" height="100%" playsinline
      :poster="isTemplatePlaceholder ? null : (isLocal ? null : (blob.video.src + '_poster_0'))"
      :src="isTemplatePlaceholder ? null : src"
      @loadeddata="onLoadedData"
    />

  </div>
</template>

<style scoped>
.template-editable {
  box-sizing: border-box;
  border: 1px dashed rgb(25, 118, 210);
  transition: background-color .2s;
}
.template-editable:hover {
  background-color: rgb(25, 118, 210, .08);
}

.playback {
  /* These seem redundant, but they're necessary for proper cross-browser compatibility */
  height: 100%;
  min-height: 100%;
}
</style>

<script>
import { getMetadata } from '@/utils/video.js';


if (!window.LOCAL_VIDEOS_BY_BLOB_ID)
  window.LOCAL_VIDEOS_BY_BLOB_ID = {};


export default {
  name: 'video-blob',

  props: [ 'blob', 'isPreview', 'isEditable', 'isSelected', 'isPageActive' ],

  data() {
    return {
      playback: null,
      error: false,
      isPaused: true,
      playhead: 0,
      duration: 0,
      loadStatus: this.$track('load'),
      reloadStatus: this.$track('reload')
    };
  },

  computed: {
    crop() {
      const styles = {};
      if (this.blob.crop) {
        for (const key of [ 'top', 'right', 'bottom', 'left' ])
          styles[key] = `-${this.blob.crop[key] || 0}%`;
        if (this.blob.crop.r)
          styles.transform = `rotate(${this.blob.crop.r}deg)`;
      }
      return styles;
    },

    isTemplatePlaceholder() {
      return this.blob.template && (this.blob.mask.placeholder == this.blob.video.src);
    },

    isLocal() {
      return this.blob.video.src.startsWith('blob');
    },

    src() {
      if (this.isLocal)
        return this.blob.video.src;
      if (window.LOCAL_VIDEOS_BY_BLOB_ID[ this.blob.id ])
        return window.LOCAL_VIDEOS_BY_BLOB_ID[ this.blob.id ];
      return this.blob.video.src + '.mp4';
    }
  },

  mounted() {
    const playback = this.$refs.playback;

    // Chrome is terrible at computing the duration of new recordings. There are all sorts of workarounds, but the
    // simplest thing is just to keep track of the duration yourself. I was able to get the "fix" descibed in the SO
    // link to work for computing the duration, but that royally screwed up the simple play / pause functionality
    // unless you seek somewhere in the video first). Chrome does seem to compute the exact duration as it plays the
    // video, which will vary by a tiny amount from what we computed. I defer to Chrome's value and update the slider
    // as the video plays, which is weird, but the impact on the user is impreceptible.
    // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175

    // Set up the event listeners to update the playhead
    playback.addEventListener(
      'durationchange',
      () => {
        if (!isFinite(playback.duration))
          return;
        this.duration = playback.duration;
      }
    );
    playback.addEventListener('ended', () => { this.isPaused = true; this.seek(0); });
    playback.addEventListener('timeupdate', () => this.playhead = playback.currentTime);
  },

  methods: {
    play() {
      this.isPaused = false;
      this.$refs.playback?.play();
    },

    pause() {
      this.$refs.playback?.pause();
      this.isPaused = true;
    },

    playPause() {
      if (this.isPaused)
        this.play();
      else
        this.pause();
    },

    seek(pct) {
      const playback = this.$refs.playback;
      if (!playback)
        // This gets triggered initially when the value first gets set on the slider, hence the guard
        return;
      if ((pct == 0 || pct == 100) && (playback.currentTime == 0 || playback.currentTime == this.duration))
        // Without this check, we'd see the playhead stutter and bounce between the end and start if we try to scrub
        // past the end of the video
        return;
      playback.currentTime = (pct / 100.0) * this.duration;
    },

    async load() {
      this.loaded = false;
      if (this.blob.template && (this.blob.mask.placeholder == this.blob.video.src))
        // We'll load this as an image
        return;
      this.$refs.playback.load();
      try {
        const metadata = await getMetadata(this.src);
        this.duration = metadata.duration;
        this.error = false;
      } catch (e) {
        this.error = true;
      } finally {
        this.loaded = true;
      }
    },

    async reload() {
      await new Promise(r => setTimeout(r, 3000));
      await this.load();
    },

    async onLoadedData() {
      // This seems redundant, but it ensures that the video-blob-controls will correctly reflect the media state by
      // the time we emit the 'loaded' event
      this.error = false;
      this.loaded = true;
      await this.$nextTick();
      this.$emit('loaded');
    }
  },

  watch: {
    isPageActive(v) {
      if (!v)
        this.pause();
    },

    isPaused(v) {
      if (!v)
        this.$emit('play-video');
    },

    src: {
      immediate: true,
      async handler(cur, prev) {
        if (cur != prev) {
          await this.$nextTick();
          await this.load();
        }
      }
    }
  }
}
</script>
