var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    attrs: {
      "name": "Start"
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_c('start-btn-list', {
    staticClass: "transparent"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }