// Rotates a point about the center point given r in radians
function rotate(center, p, r) {
  const [ cx, cy ] = center;
  const [ x, y ] = p;
  const cos = Math.cos(r);
  const sin = Math.sin(r);
  return [ (cos * (x - cx)) + (sin * (y - cy)) + cx, (cos * (y - cy)) - (sin * (x - cx)) + cy ];
}

function translate(p0, p1) {
  return [ p0[0] + p1[0], p0[1] + p1[1] ];
}

function scale(p, s) {
  return [ p[0] * s, p[1] * s ];
}

export { rotate, translate, scale };
