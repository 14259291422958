<template>
  <v-list-item v-bind="$attrs" v-on="$listeners">
    <v-list-item-icon class="ma-2" style="width: 48px;">
      <account-order-preview mode="purchase" :width="48" :order="order" :is-card-owner="true" />
    </v-list-item-icon>
    <v-list-item-content>
      <slot>
        <div class="text--label-active">Last updated</div>
        <div class="ec-dark-blue--text text-body-2">{{order.updated_at | moment}}</div>
      </slot>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon color="ec-dark-blue" v-html="mdiChevronRight" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import AccountOrderPreview from '@/components/AccountOrderPreview.vue';
import { mdiChevronRight } from '@mdi/js';

export default {
  name: 'cart-list-item',

  components: {
    AccountOrderPreview
  },

  props: [ 'orderId', 'order' ],

  created() {
    Object.assign(this, { mdiChevronRight });
  }
}
</script>
