var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.prefix ? _c('span', {
    staticClass: "prefix",
    domProps: {
      "innerHTML": _vm._s(_vm.code)
    }
  }) : _vm._e(), _vm.negative ? _c('span', [_vm._v("−")]) : _vm._e(), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.symbol)
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }