// Use with 'v-btn' to force it to be temporary
export default {
  inserted(root) {
    // I believe I need the delay to wait until the animation is finished before removing the 'active' classes - this
    // is a kludge
    root.onfocus = e => setTimeout(() => {
      e.target.classList.remove('v-item--active', 'v-btn--active');
      e.target.blur();
    }, 500);
  }
};
