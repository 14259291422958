<template>
  <div
    v-if="!selected || (selected === blob)"
    class="absolute-fill d-flex justify-center align-center"
    v-bind:class="{ 'align-end': !!blob.group_video.img_src }"
  >
    <div style="pointer-events: auto;" Xv-bind:style="{ transform: `scale(${1 / scale}) rotate(${-blob.r}deg)` }">
      <v-btn v-if="blob.group_video.img_src" v-blur rounded dark elevation="6" color="primary" @click="$emit('click')">
        {{[ 'purchase', 'edit' ].includes(mode) ? 'Add to' : 'Play'}} video
      </v-btn>
      <v-btn v-else v-blur fab x-large dark color="primary" @click="$emit('click')">
        <v-icon x-large v-html="[ 'purchase', 'edit' ].includes(mode) ? mdiPencil : mdiPlay" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiPencil, mdiPlay } from '@mdi/js';

export default {
  name: 'group-video-blob-controls',

  props: [ 'blob', 'selected', 'isOwner', 'scale', 'mode' ],

  created() {
    Object.assign(this, { mdiPencil, mdiPlay });
  }
}
</script>
