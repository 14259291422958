<template>
  <v-responsive
    v-bind="$attrs"
    class="overflow-visible"
    content-class="v-responsive-bounded absolute-fill"
    :aspect-ratio="aspectRatio"
  >
    <slot v-if="bounds && bounds.w" v-bind:bounds="bounds"></slot>
  </v-responsive>
</template>

<style>
.v-responsive-bounded {
  margin-left: unset !important;
}
</style>

<script>
// This component determines its height automatically based on the width of the parent container, resizes its own
// height accordingly, and delivers the resulting bounds to the slot (ane emits an 'updated' event containing the new
// bounds). It differs from the 'v-responsive' component in that it actually computes the dimensions of the bounds, but
// is more expensive because it relies on the resize observer.

export default {
  name: 'v-responsive-bounds',

  props: [ 'aspectRatio' ],

  data () {
    return {
      w: null,
      h: null
    }
  },

  computed: {
    bounds() {
      return { w: this.w, h: this.h };
    }
  },

  beforeDestroy() {
    this.unobserve();
  },

  methods: {
    async onResize() {
      // Don't update the bounds until the next loop to ensure we don't interfere with the ResizeObserver
      setTimeout(() => { this.w = this.$el.clientWidth; this.h = this.$el.clientHeight; });
    },

    onResizeDelay() {
      // For browsers that don't support the ResizeObserver, this will at least capture when the size of the window
      // changes
      this.onResizeTimeout = setTimeout(this.onResize, 500);
    },

    observe() {
      if (window.ResizeObserver) {
        this.resizeObserver = new ResizeObserver(this.onResize);
        this.resizeObserver.observe(this.$el);
      } else {
        addEventListener('resize', this.onResizeDelay);
        this.onResizeDelay();
      }
    },

    unobserve() {
      clearTimeout(this.onResizeTimeout);
      removeEventListener('resize', this.onResizeDelay);
      this.resizeObserver && this.resizeObserver.unobserve(this.$el);
    }
  },

  watch: {
    width: {
      immediate: true,
      async handler(w) {
        this.w = this.$attrs.width;
        if (w) {
          this.unobserve();
          await this.$nextTick();
          this.onResize();
        } else {
          await this.$nextTick();
          this.observe();
        }
      }
    },

    bounds(x) {
      this.$emit('updated', x);
    }
  }
}
</script>
