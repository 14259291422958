// Attaches the classes 'intersect-in' or 'intersect-out' based on whether or not the element intersects the viewport
export default {

  bind(el) {
    const o = new IntersectionObserver(
      entries => {
        for (const { target, isIntersecting } of entries) {
          target.classList[ isIntersecting ? 'add' : 'remove' ]('intersect-in');
          target.classList[ isIntersecting ? 'remove' : 'add' ]('intersect-out');
        }
      }
    );
    el._vIntersectClassObserver = o;
    o.observe(el);
  },

  unbind(el) {
    el._vIntersectClassObserver.disconnect();
  }

};
