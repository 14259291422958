<template>
  <v-list dense>
    <v-list-item
      v-for="[ to, title, icon ], i in ACTIONS" :key="title"
      class="li-pop ec-dark-blue--text"
      v-bind:class="{ 'mb-6': i == 2 || i == 6, 'font-weight-bold': i < 3 }"
      :to="to"
    >
      <v-icon small v-html="icon" />
      <div v-html="title" />
      <v-icon small v-html="mdiChevronRight" />
    </v-list-item>
  </v-list>
</template>


<script>
import { ACTIONS } from '@/utils/start-actions.js';
import { mdiChevronRight } from '@mdi/js';

export default {
  name: 'start-btn-list',
  created() {
    Object.assign(this, { ACTIONS, mdiChevronRight });
  }
}
</script>
