import confirm from '@/mixins/confirm.js';
import notify from '@/mixins/notify.js';

export default {
  mixins: [ confirm, notify ],

  methods: {
    // Note that 'text' can also ba a promise that eventually returns text
    // Set 'asConvenience' to true if the text in question is already visible (and selectable)
    copyToClipboard(text, opts={ msg: 'Copied', asConvenience: true }) {
      const textPromise = Promise.resolve(text);
      const blobPromise = textPromise.then(text => new Blob([ text ], { type: 'text/plain' }));
      navigator.clipboard.write([ new ClipboardItem({ 'text/plain': blobPromise }) ]).then(
        () => this.$notify(opts.msg || 'Copied'),
        async () => this.$confirm({
          title: opts.asConvenience ? undefined : 'Select and copy text',
          msg: opts.asConvenience ? 'Could not write to your clipboard, please select and copy manually' : (await textPromise),
          buttons: [ 'Ok' ],
          primary: 'Ok'
        })
      );
    }
  }
};
