// Use with 'v-select' to allow it to grow / expand based on the width of the selected value
export default {
  inserted(root, binding, vnode) {
    const inputNode = root.getElementsByTagName('input')[0];
    inputNode.style['width'] = '0';
    const labelNode = root.getElementsByTagName('label')[0];
    if (labelNode) {
      labelNode.style['overflow'] = 'visible';
    }
  }
};
