const CACHE = {};

async function loadScript(src, attrs=undefined) {
  if (!(src in CACHE))
    CACHE[src] = new Promise((resolve, reject) => {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = src;
      s.async = true;
      s.defer = true;
      s.onerror = reject;
      s.onload = resolve;
      if (attrs) {
        for (const [ attr, value ] of Object.entries(attrs))
          s.setAttribute(attr, value);
      } else {
        s.setAttribute('data-cookiescript', 'accepted');
      }
      document.head.appendChild(s);
    });
  return await CACHE[src];
}


export default loadScript;
