import variables from '@/styles/variables.js';

const DEFAULT = {
  card_fixed_last_page: null,
  open_effect: null,
  music: null,
  env_background_color: variables['ec-light-blue'],
  env_font: 'Architects Daughter',
  env_font_color: variables['ec-dark-blue'],
  env_stamp: 'https://cdn.ellacard.com/stamps/Ellacard.png',
  header_logo: 'https://ellacard.com/ellacard-logo-filled-307x160.png',
  hide_account: false,
  max_giftcard_value: 500000,
  hide_giftcard_totals: false,
  disable_contributor_giftcards: false,
  page_background_color: '#FFF',
  page_background_img: null,
  done_url: null,
  disable_reply: false,
  max_n_recipients: 500,
  email_from: null,
  email_subject: 'You received a card',
  email_footer_logo: 'https://ellacard.com/ellacard-logo-filled-307x160.png',
  email_footer_url: 'https://ellacard.com/',
  email_unsubscribe_url: null,
  private_pages: false
};

// There are lots of options for rendering confetti using JS. This library is lightweight, relatively simple, and
// customizable enough for a basic set of effects. It does not support images though, like this one does:
// https://codepen.io/MAW/pen/KdmwMb
// https://github.com/catdad/canvas-confetti
// https://www.kirilv.com/canvas-confetti/
const EFFECTS = {
  confetti: {
    density: .1,
    size: .4,
    wind: .5,
    gravity: .4,
    colors: [ '#1E90FF', '#6b8E23', '#FFD700', '#FFC0CB', '#6A5ACD', '#ADD8E6', '#EE82EE', '#98FB98', '#4682B4', '#F4A460', '#D2691E', '#DC143C' ]
  },
  romantic: {
    density: .2,
    size: .5,
    wind: .5,
    gravity: .25,
    colors: [ '#E91E63', '#F44336', '#FCE4EC' ]
  },
  snow: {
    density: 1,
    size: 0,
    wind: .5,
    gravity: 0,
    colors: [ '#FFF' ]
  },
  rain: {
    density: 1,
    size: 0,
    wind: 0,
    gravity: 1,
    colors: [ '#1976D2', '#4FC3F7', '#90CAF9' ]
  },
  summer: {
    density: .5,
    size: .25,
    wind: .5,
    gravity: .75,
    colors: [ '#FCFF42', '#FFF59D', 'FFFF00', '#FFEB3B' ]
  },
  fall: {
    density: .3,
    size: .25,
    wind: .5,
    gravity: .25,
    colors: [ '#795548', '#FF9800', '#B71C1C' ]
  }
};

const THEME_KEYS = [
  'page_background_color',
  'page_background_img',
  'open_effect',
  'env_stamp',
  'env_font_color',
  'env_background_color',
  'env_font',
  'music',
  'header_logo',
  'email_footer_logo',
  'email_footer_url'
];

export { DEFAULT, EFFECTS, THEME_KEYS };
