var render = function render(){
  var _vm$global$profile, _vm$global$profile2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    ref: "menu",
    attrs: {
      "name": "For business",
      "title": "Tools and discounts for businesses",
      "subtitle": "Employee appreciation and outreach to customers / donors",
      "action": "Business features",
      "icon": _vm.mdiDomainPlus,
      "to": {
        name: 'business'
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_vm.showSignUp ? _c('org-sign-up-dialog', {
    attrs: {
      "value": true
    },
    on: {
      "cancel": function ($event) {
        _vm.showSignUp = false;
      },
      "complete": function ($event) {
        return _vm.$router.push({
          name: 'account-org'
        });
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "root-grid"
  }, [_c('div', [_c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, [(_vm$global$profile = _vm.global.profile) !== null && _vm$global$profile !== void 0 && _vm$global$profile.org_admin ? _c('v-list-item', {
    staticClass: "li-pop ec-dark-blue--text",
    attrs: {
      "exact": "",
      "to": {
        name: 'account-org'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiDomain)
    }
  }), _c('div', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Manage organization")])]), _c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiChevronRight)
    }
  })], 1) : (_vm$global$profile2 = _vm.global.profile) !== null && _vm$global$profile2 !== void 0 && _vm$global$profile2.org ? _c('div', {
    staticClass: "pt-3 px-3 caption"
  }, [_vm._v("Organization tools")]) : _c('v-list-item', {
    staticClass: "li-pop font-weight-bold ec-dark-blue--text",
    attrs: {
      "to": {
        name: 'account-org'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiDomainPlus)
    }
  }), _c('div', [_vm._v("Create an organization account…")]), _c('v-chip', {
    staticClass: "overline short",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "color": "green",
      "dark": "",
      "label": "",
      "small": ""
    }
  }, [_vm._v("free")])], 1)], 1), _c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.ACTIONS, function (_ref) {
    var route = _ref[0],
      name = _ref[1],
      icon = _ref[2],
      filter = _ref[3];
    return filter(_vm.global.profile) ? _c('v-list-item', {
      key: name,
      staticClass: "li-pop ec-dark-blue--text",
      attrs: {
        "exact": "",
        "to": route
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _c('div', {
      domProps: {
        "textContent": _vm._s(name)
      }
    }), _c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(_vm.mdiChevronRight)
      }
    })], 1) : _vm._e();
  }), 1)], 1), _c('div', [_c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticClass: "li-pop font-weight-bold ec-dark-blue--text mb-4",
    attrs: {
      "to": {
        name: 'start-business'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiInformation)
    }
  }), _c('div', [_vm._v("Learn more about our business platform")]), _c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiChevronRight)
    }
  })], 1), _vm._l(_vm.LEARN_MORE, function (_ref2) {
    var route = _ref2[0],
      name = _ref2[1],
      icon = _ref2[2];
    return _c('v-list-item', {
      key: name,
      staticClass: "li-pop ec-dark-blue--text",
      attrs: {
        "exact": "",
        "to": route
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _c('div', {
      domProps: {
        "textContent": _vm._s(name)
      }
    }), _c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(_vm.mdiChevronRight)
      }
    })], 1);
  })], 2), _c('router-link', {
    staticClass: "mx-auto my-6 d-block relative hover-pop-group",
    staticStyle: {
      "aspect-ratio": "20 / 17",
      "max-width": "min(400px, calc(100% - 24px))"
    },
    attrs: {
      "to": {
        name: 'start-business'
      }
    }
  }, [_c('img', {
    staticClass: "rounded elevation-20 absolute",
    staticStyle: {
      "top": "0",
      "left": "10%",
      "width": "70%"
    },
    attrs: {
      "loading": "lazy",
      "type": "image/webp",
      "src": "https://cdn.ellacard.com/media/2de601ae-067d-491c-9750-16a518a3e133/lg.webp",
      "srcset": "https://cdn.ellacard.com/media/2de601ae-067d-491c-9750-16a518a3e133/sm.webp" + " 320w, " + "https://cdn.ellacard.com/media/2de601ae-067d-491c-9750-16a518a3e133/md.webp" + " 640w, " + "https://cdn.ellacard.com/media/2de601ae-067d-491c-9750-16a518a3e133/lg.webp" + " 1280w",
      "alt": "Business tools / users"
    }
  }), _c('img', {
    staticClass: "rounded elevation-20 absolute",
    staticStyle: {
      "right": "12px",
      "bottom": "12px",
      "width": "70%"
    },
    attrs: {
      "loading": "lazy",
      "type": "image/webp",
      "src": "https://cdn.ellacard.com/media/5a52ae1d-b5e9-4b51-856b-a4c93a527c1c/lg.webp",
      "srcset": "https://cdn.ellacard.com/media/5a52ae1d-b5e9-4b51-856b-a4c93a527c1c/sm.webp" + " 320w, " + "https://cdn.ellacard.com/media/5a52ae1d-b5e9-4b51-856b-a4c93a527c1c/md.webp" + " 640w, " + "https://cdn.ellacard.com/media/5a52ae1d-b5e9-4b51-856b-a4c93a527c1c/lg.webp" + " 1280w",
      "alt": "Business tools / user activity"
    }
  }), _c('img', {
    staticClass: "rounded elevation-20 absolute",
    staticStyle: {
      "left": "12px",
      "bottom": "10%",
      "width": "60%"
    },
    attrs: {
      "loading": "lazy",
      "type": "image/webp",
      "src": "https://cdn.ellacard.com/media/01508846-a32d-4878-8584-b3831eb8e677/lg.webp",
      "srcset": "https://cdn.ellacard.com/media/01508846-a32d-4878-8584-b3831eb8e677/sm.webp" + " 320w, " + "https://cdn.ellacard.com/media/01508846-a32d-4878-8584-b3831eb8e677/md.webp" + " 640w, " + "https://cdn.ellacard.com/media/01508846-a32d-4878-8584-b3831eb8e677/lg.webp" + " 1280w",
      "alt": "Import contacts"
    }
  })])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }