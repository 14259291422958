<template>
  <div
    v-if="target && !(target.blob.template && (target.blob.mask.placeholder == target.blob.video.src))"
    class="video-blob-controls absolute-fill d-flex justify-center align-center"
    v-bind:class="{
      'is-playing': !target.error && !target.isPaused,
      'is-editable': isEditable,
      'is-selected': isSelected
    }"
    @click="
      () => {
        if (isEditable && !isSelected && target.isPaused)
          $emit('select');
        else
          target.playPause();
      }
    "
  >

    <v-dialog v-if="showProcessing" width="300" v-model="showProcessing">
      <v-card>
        <v-btn class="float-right mt-3 mr-3" icon @click="showProcessing = false">
          <v-icon v-html="mdiClose" />
        </v-btn>
        <v-card-title>Video processing</v-card-title>
        <v-card-text class="d-flex align-center">
          Status:
          <span class="mx-2 warning--text">in progress</span>
          <v-btn small icon color="primary" :loading="target.reloadStatus.pending" @click="target.reload">
            <v-icon v-html="mdiRefresh" />
          </v-btn>
        </v-card-text>
        <v-card-text>This video is still being processed so that it can play back in all web browsers / devices.</v-card-text>
      </v-card>
    </v-dialog>

    <v-btn
      v-if="target.error"
      v-blur
      class="control"
      fab dark color="primary"
      :loading="target.reloadStatus.pending"
      @click.stop="showProcessing = true"
    >
      <v-icon large v-html="mdiPlay" />
    </v-btn>

    <template v-else>
      <v-btn
        v-blur
        class="control"
        fab dark color="primary"
        :disabled="target.loadStatus.pending"
      >
        <v-icon large v-html="target.isPaused ? mdiPlay : mdiPause" />
      </v-btn>

      <div class="playback-details">
        <v-slider
          class="control playback-slider px-3"
          hide-details step="0"
          :disabled="target.loadStatus.pending"
          :value="(target.playhead / target.duration) * 100.0"
          @input="target.seek"
          @start="target.pause"
          @click.stop="
            () => {
              if (isEditable && !isSelected && target.isPaused)
                $emit('select');
            }
          "
          @mousedown.stop
          @touchstart.native.stop
        />
        <div class="d-flex px-3 pb-1 white--text timestamp-shadow">
          <div>{{target.playhead | video-timestamp}}</div>
          <v-spacer />
          <div>{{target.duration | video-timestamp}}</div>
        </div>
      </div>
    </template>

  </div>
</template>


<style scoped>
.video-blob-controls {
  transition: opacity .6s;
  opacity: 1;
  pointer-events: none;
  cursor: pointer;
}

.video-blob-controls.is-playing {
  opacity: 0;
  pointer-events: auto;
}

.video-blob-controls .control {
  pointer-events: auto;
}

.video-blob-controls.is-editable:not(.is-selected) .control {
  pointer-events: none;
}

.playback-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.timestamp-shadow {
  text-shadow: 0 4px 16px #000000;
}

.playback-slider {
  height: 24px;
  filter:
    drop-shadow(0 5px 2px rgb(0 0 0 / 10%))
    drop-shadow(0 8px 9px rgb(0 0 0 / 10%))
    drop-shadow(0 3px 12px rgb(0 0 0 / 10%));
}
</style>


<style>
.playback-slider .v-slider.v-slider--horizontal {
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
}
.playback-slider .v-slider__thumb-container,
.playback-slider .v-slider__track-background,
.playback-slider .v-slider__track-fill {
  transition: none;
}
</style>


<script>
import { mdiPlay, mdiPause, mdiClose, mdiRefresh } from '@mdi/js';

export default {
  name: 'video-blob-controls',

  props: [ 'target', 'isEditable', 'isSelected' ],

  data() {
    return {
      showProcessing: false
    };
  },

  created() {
    Object.assign(this, { mdiPlay, mdiPause, mdiClose, mdiRefresh });
  },

  watch: {
    isSelected(v) {
      if (!v)
        this.target.pause();
    },

    'target.error'(v) {
      if (!v)
        this.showProcessing = false;
    }
  }
}
</script>
