import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { ClickOutside, Touch } from 'vuetify/lib/directives';
import { mask } from 'vue-the-mask';
import blur from '@/directives/blur.js';
import noScroll from '@/directives/no-background-scroll.js';
import elResize from '@/directives/el-resize.js';
import hrefFallback from '@/directives/href-fallback.js';
import inputInLine from '@/directives/input-in-line.js';
import selectInLine from '@/directives/select-in-line.js';
import variables from '@/styles/variables.js';


Vue.use(
  Vuetify,
  { directives: { ClickOutside, Touch, mask, blur, elResize, hrefFallback, noScroll, inputInLine, selectInLine } }
);


export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: variables
    }
  }
});
