<template>
  <!-- There are bugs on iOS with simply assigning 'overflow-hidden' to this outer class, hence the additional use of clip-path. Note that clip-path itself wasn't enough to cover your old version of desktop Safari. -->
  <div
    v-if="mask"
    class="absolute overflow-hidden"
    style="clip-path: content-box;"
    v-bind:style="{ ...blobMaskTransform, 'mask-image': mask.img }"
  >
    <div class="absolute" v-bind:style="blobMaskRelativeTransform">
      <slot></slot>
    </div>
  </div>
  <div v-else class="absolute" v-bind:style="blobTransform">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'blob-mask',
  props: [ 'mask', 'blobTransform', 'blobMaskTransform', 'blobMaskRelativeTransform' ]
}
</script>
