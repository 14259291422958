var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.mask ? _c('div', {
    staticClass: "absolute overflow-hidden",
    staticStyle: {
      "clip-path": "content-box"
    },
    style: Object.assign({}, _vm.blobMaskTransform, {
      'mask-image': _vm.mask.img
    })
  }, [_c('div', {
    staticClass: "absolute",
    style: _vm.blobMaskRelativeTransform
  }, [_vm._t("default")], 2)]) : _c('div', {
    staticClass: "absolute",
    style: _vm.blobTransform
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }