<template>
  <div :key="blob.giftcard.id" class="absolute-fill elevation-8 gc-border">
    <div v-if="placeholder" class="absolute-fill placeholder" />
    <img class="user-select-none gc-border" :src="blob.giftcard.product_img" @load="onLoad" draggable="false" />
  </div>
</template>

<style scoped>
img {
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.placeholder {
  box-sizing: border-box;
  border: 4px dashed rgba(0, 0, 0, .1);
}
</style>

<script>
export default {
  name: 'giftcard-blob',

  props: [ 'blob' ],

  data() {
    return {
      placeholder: true
    };
  },

  methods: {
    onLoad() {
      this.placeholder = false;
      this.$emit('loaded');
    }
  }
}
</script>
